.disabled-wrapper {
    position: relative;
    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: white;
        opacity: 0.4;
    }
}
