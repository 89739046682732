.assignEnrollmentsContainer {
    padding: 30px;
    .enrollmentIdLabel {
        text-align: right;
        padding-top: 5px;
    }
}

.customBtn {
    width: 100px;
    padding: 5px 0px;
    border-radius: 10px;
    border: none;
    color: white;
}