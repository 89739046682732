.root {
    width: 50vw;
    min-height: 400px;
    padding-bottom: 50px;
    background-color: #fff;
    position: relative;
}

.content {
    padding: 16px;
}

.content-left {
    overflow-y: auto;
    max-height: 300px;
    padding: 8px;
    border: 1px solid #f2f2f2;
}

.content-right {
    overflow-y: hidden;
}

.caption {
    margin-bottom: 16px;
    color: #1675a9;
    font-weight: normal;
}

.btn {
    border: none;
    padding: 6px 14px;
    background-color: transparent;
    color: #0a5b88;
    margin: 4px 8px;
}

.btn:hover,
.btn:active,
.btn:focus {
    border: none;
    outline: none;
}

.btn-primary {
    border: none;
    background-color: #0a5b88;
    color: #fff;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    align-items: center;
    color: #064769;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
}

.icon {
    color: #064769;
}

.icon-inverted {
    color: #fff;
}

.btn-primary[disabled] {
    background-color: #9a9b9c !important;
}

.btn-primary:hover,
.btn-primary:focus {
    border: none;
    background-color: #064769;
}

.unassigned {
    color: red;
}

.assigned {
    color: green;
}

.id {
    color: grey;
}

.footer {
    position: absolute;
    bottom: 0;
    padding: 8px;
    left: 0;
    right: 0;
    height: 50px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    color: #1675a9;
    border: solid 1px rgb(0, 192, 239);
    background-color: #eefbff;
}
