.timeLineGroup {
    label {
        color: #424242;
    }

    input {
        height: 32px;
        width: 100%;
        padding-left: 0.5rem !important;
        background-color: #fafafa;
        border: 1px solid #9c9c9c;
    }
}
