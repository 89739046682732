.notepad-container {
    padding: 0 0 0 10px;
    position: relative;
    display: flex;
    min-width: auto;
}

.notepad-icon {
    color: white;
    cursor: pointer;
    padding: 0 5px;
}

.dismiss-icon {
    color: white;
    cursor: pointer;
    margin: 12px 0 0 0;
}
