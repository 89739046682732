$success-color: #8db600;
$success-bg: #daf0b7;
$error-color: #ff582e;
$error-bg: #ffe0d7;
$info-color: #1698a9;
$info-bg: #d4f5ff;
$warning-color: #e9a831;
$warning-bg: #fce2b1;
$text-color: #000;
$icon-margin-right: 1rem;

@mixin toastStyle($borderColor, $bgColor) {
    border-color: $borderColor !important;
    background-color: $bgColor !important;
}

@mixin toastIconStyle($iconColor) {
    color: $iconColor;
    margin-right: $icon-margin-right;
}

.toast {
    &[class~='e-toast-container'] {
        & > div {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            width: auto !important;
            min-width: 400px;
            max-width: 600px;
            border: 1px solid !important;
            border-left-width: 0.6rem !important;
            padding: 8px 14px 8px 8px !important;
            & > div:last-child {
                color: $text-color !important;
                font-size: 10px !important;
            }
        }
        .toastSuccess {
            @include toastStyle($success-color, $success-bg);
        }
        .toastError {
            @include toastStyle($error-color, $error-bg);
        }
        .toastWarning {
            @include toastStyle($warning-color, $warning-bg);
        }
        .toastInfo {
            @include toastStyle($info-color, $info-bg);
        }
    }
}

.content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .title {
        color: $text-color;
        margin-right: $icon-margin-right;
    }
    .text {
        color: $text-color;
    }
}

.iconSuccess {
    @include toastIconStyle($success-color);
}
.iconError {
    @include toastIconStyle($error-color);
}
.iconWarning {
    @include toastIconStyle($warning-color);
}
.iconInfo {
    @include toastIconStyle($info-color);
}
