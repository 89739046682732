.progress-primary {
    width: 120px;
    height: 32px;
    text-transform: capitalize !important;
    color: white !important;
}

.progress-search-button {
    width: 120px;
    height: 32px;
    margin-right: 40px;
    border-radius: 8px;
    background-color: #03659a;
    color: white;
}

.clear-filters.e-btn {
    width: 120px;
    height: 32px;
    margin-right: 40px;
    border-radius: 8px;
    color: white;
    background-color: #767679;
}

.filters-vertical-flex {
    display: flex;
    flex-direction: column;
}

.filters-horizontal-flex {
    flex-direction: row;
}

.filters-filter-controls {
    display: flex;
    flex-shrink: 0;
    margin-left: 25px;
    margin-bottom: 10px;
    align-self: center;
}

.filters-filter-control {
    display: flex;
    flex-shrink: 0;
    margin-left: 25px;
    align-self: flex-end;
    margin: 10px;
}

.filters-horizontal-flex .filters-filter-control {
    align-self: baseline;
    margin: 0 10px;
}

.new-record.e-btn {
    height: 32px;
    margin-left: 40px;
    border-radius: 8px;
    color: white;
    background-color: #767679;
}

.filter-input {
    margin-right: 10px;
    margin-bottom: 8px;
}

.filters-horizontal-flex .filter-input {
    margin-bottom: 0;
}

.e-progress-btn.filters-panel-search {
    position: relative;
    width: 120px;
    height: 32px;
    border-radius: 8px;
    text-transform: uppercase;
    color: white;
    background-color: #03659a;
}

.e-progress-btn.filters-panel-search:hover {
    background-color: #03659a;
}

.e-progress-btn.filters-panel-search:focus {
    background-color: #03659a;
}

.e-progress-btn.filters-panel-search:active {
    background-color: #03659a;
}

.e-progress-btn.filters-panel-search .e-btn-content {
    color: white;
}

.filter-panel-error-message {
    color: red;
    font-size: 11px;
}

#filters-container .e-dropdownbase {
    height: 22px;
    min-height: 22px;
}

.filter-input .e-checkbox-wrapper.e-wrapper {
    margin-top: 6px;
}
