.fieldEditableSection {
    margin: 10px;
}

.fieldsSection {
    justify-content: flex-start;
    border-bottom: solid 2px rgb(186, 235, 249);
    margin-bottom: 5px;
}

.fieldsSection > .editable {
    width: 80%;
    margin-left: 10px;
}

.e-expand-state .e-active {
    height: 400px;
}

.create-account-container {
    padding: 15px;
}

.error-form {
    color: red;
    margin-bottom: 10px;
}

.field-col {
    height: 65px;
}

.btn-create {
    max-width: 100px;
}

.form-section {
    margin-top: 35px;
    padding: 5px;
    position: relative;
    margin-right: -5px !important;
}

.form-section > label {
    position: absolute;
    top: -25px;
    left: 0;
    font-size: 14px;
}

.disable-field {
    padding: 0 !important;
}

.field-margin-top {
    margin-top: 20px;
}

.disable-field input {
    pointer-events: none;
    background-color: #fafafa !important;
    cursor: not-allowed;
    padding-left: 5px;
}

.tab-content {
    padding: 10px 0;
    background-color: #fff;
}

.grid-container {
    margin-bottom: 25px;
}

.ipa-tab-form {
    padding: 15px;
}

.ipa-card-content {
    padding: 15px;
    color: #000;
    text-align: center;
}

.ipa-card-content > .row > div > p {
    padding-top: 5px;
    border-top: solid 2px rgb(186, 235, 249);
    white-space: pre;
    font-size: 14px;
}

/* .e-checkbox-wrapper {
    padding: 5px;
} */
.center-fields {
    align-items: center;
    margin-bottom: 10px;
}
.header-margin {
    margin: 20px 0 !important;
}
.action-text {
    margin: 5px 0;
    color: #00b3e3;
    cursor: pointer;
}

.cap-rates-form {
    padding: 15px;
}

.formula-description {
    font-size: 15px;
    margin-bottom: 0px !important;
}

.accordion-content {
    padding: 10px;
}

.datepicker .e-input-group {
    max-height: 32px;
}

.e-grid div.e-toolbar-item.e-cc.e-ccdiv.e-cc-toolbar {
    padding-top: 0;
    margin: 0;
}

.no-padding {
    padding: 0;
}

.column-padding {
    padding-right: 50px;
}

.section-container-header {
    color: #03659a;
    font-size: 16px;
    margin: 10px;
    font-weight: 500;
    margin-bottom: 0px;
    border-bottom: 1px solid #03659a;
    margin-bottom: 10px;
}

.e-input-group-margin {
    margin-bottom: 12px;
}

.medical-group-admin .e-grid td.e-active {
    background-color: rgb(0, 179, 227);
}

.medical-group-admin .e-grid td.e-active:hover {
    background-color: rgb(0, 179, 227);
}

.e-label-select,
.e-float-text {
    color: #000 !important;
    margin-bottom: 0px;
}

labelWrap {
    color: #000;
}

.infoStepsMainWrap {
    border: 2px solid #c8c8c8;
    border-radius: 8px;
}

.stepsWrapper {
    display: flex;
    justify-content: space-between;
}

.customStepsBtn {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
}

.arrowBox {
    width: 23%;
}

.arrowBox-active {
    position: relative;
    background: #00b3e3;
    height: 40px;
    line-height: 40px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
}

.customStepsBtn {
    color: #fff;
}

.arrowBox-Inactive {
    position: relative;
    background: #d4d4d4;
    height: 40px;
    line-height: 40px;
    margin-bottom: 15px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.arrow-right:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #fff;
}

.arrowActiveright:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #fff;
}

.arrow-right:after {
    content: '';
    position: absolute;
    right: -19px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #d4d4d4;
}

.arrowActiveright:after {
    content: '';
    position: absolute;
    right: -19px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #00b3e3;
}

.arrowActiveright:nth-last-child(-n + 1):after {
    content: '';
    position: absolute;
    right: -19px;
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid #00b3e3;
}

.arrowActiveright:nth-child(1):before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid #ff0000;
}

.arrow-right:nth-last-child(-n + 1):after {
    content: '';
    position: absolute;
    right: -20px;
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid #00b3e3;
}

.arrow-right:nth-child(1):before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid #ff0000;
}

.buttonsWrapper {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.labelInputWrap {
    display: flex;
    margin-bottom: 6px;
}

.customLabel {
    width: 30%;
    font-weight: 500;
}

.customInputField {
    border: none;
    font-size: 1.2em;
    margin-bottom: 0px;
}

.lblCls {
    font-size: 13px !important;
    width: 70%;
}

.fontWeightNormal {
    font-weight: 500 !important;
}

fontWeightBold {
    font-weight: 600 !important;
}

.customStepsBtn {
    color: #fff;
}

.arrowBox-Inactive {
    position: relative;
    background: #d4d4d4;
    height: 40px;
    line-height: 40px;
    margin-bottom: 15px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.arrow-right:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #fff;
}

.arrowActiveright:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #fff;
}

.arrow-right:after {
    content: '';
    position: absolute;
    right: -19px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #d4d4d4;
}

.arrowActiveright:after {
    content: '';
    position: absolute;
    right: -19px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #00b3e3;
}

.arrowActiveright:nth-last-child(-n + 1):after {
    content: '';
    position: absolute;
    right: -19px;
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid #00b3e3;
}

.arrowActiveright:nth-child(1):before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid #ff0000;
}

.arrow-right:nth-last-child(-n + 1):after {
    content: '';
    position: absolute;
    right: -20px;
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid #00b3e3;
}

.arrow-right:nth-child(1):before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid #ff0000;
}

.buttonsWrapper {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.labelInputWrap {
    display: flex;
    margin-bottom: 10px;
}

.customLabel {
    width: 30%;
}

.customInputField {
    border: none;
    font-size: 1.2em;
    margin-bottom: 0px;
    width: 72%;
}

.leftContainer {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}

.rightContainer {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}

.header-content {
    background-color: #fff !important;
}

.labelTxt {
    min-width: 150px;
    display: inline-block;
}

.boldCss,
.e-label {
    font-weight: 500 !important;
}

.detailsWrap div {
    display: flex;
    text-align: left;
}

.detailsHeading {
    font-weight: bolder;
    width: 30%;
    font-size: 1em;
}

.colunWrap {
    width: 8%;
}

.boldElementStyle {
    display: inline-block;
    width: 95%;
}

.paddingRight {
    padding-left: 10px;
}

.fieldsets {
    border: 1px solid #00b3e3;
    border-radius: 6px;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-top: 12px;
}

.subHeadding {
    background-color: #1675a9;
    color: #fff;
    padding: 8px 8px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 15px;
}

.content {
    padding: 15px 15px 0px;
}

@media only screen and (max-width: 767px) {
    .responsiveImgContainer {
        display: inline-block !important;
        width: 40% !important;
        float: left !important;
    }

    .responsiveContentContainer {
        display: inline-block !important;
        width: 60% !important;
        float: left !important;
    }

    .detailsWrap {
        text-align: left;
    }

    .detailsHeading {
        font-weight: bolder;
        width: 25%;
        display: inline-block;
    }

    .hideHR {
        display: none;
    }

    .ipa-card-content {
        min-height: 275px !important;
    }

    .leftContainer {
        margin-bottom: 15px !important;
    }
}
.btn-orange {
    background-color: #ed9e0c !important;
}

#provider-data-ipa-admin-search {
    &.view-only {
        .e-toolbar-items {
            #create-new,
            #Import,
            #Export {
                display: none;
            }
        }
    }
    &.hide-datatooloptions {
        .e-toolbar-items {
            #Import,
            #Export {
                display: none;
            }
        }
    }
}
