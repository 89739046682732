.uploadIcon {
    font-size: 30px;
    color: #3c8dbc;
}

.radio-label {
    font-weight: bold;
}

// .e-file-clear-btn {
//     color: #fff !important;
//     background-color: #6c757d !important;
//     border-color: #6c757d !important;
//     width: 130px !important;
//     padding: 6px !important;
//     font-size: 14px !important;
// }

// .e-file-upload-btn {
//     color: #fff !important;
//     background-color: #3c8dbc !important;
//     border-color: #3c8dbc !important;
//     width: 150px !important;
//     padding: 10px !important;
//     font-size: 14px !important;
// }

.e-upload-message {
    font-weight: bold;
    color: #ffa500;
    text-align: center;
}

.e-error-header {
    font-weight: bold;
}
