@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-treegrid/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-pdfviewer/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-querybuilder/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-filemanager/styles/material.css';
@import '../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
@import url('https://fonts.googleapis.com/css?family=Barlow|Varela&display=swap');
@import '../node_modules/@syncfusion/ej2/material.css';

html {
    scroll-behavior: smooth;
    font-size: 14px;
}

body {
    font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif', '-apple-system', 'BlinkMacSystemFont';
    font-weight: 400;
    background-color: #ecf0f5;
    overflow-x: auto;
}

h1 {
    color: #1675a9;
    font-family: 'Barlow', sans-serif;
}

.main-content {
    min-height: calc(100vh - 50px);
    min-width: 1170px;
}

.main-section {
    min-width: 1400px;
    padding: 15px;
}

.centered-content {
    max-width: 1200px;
    margin: auto;
}

.container {
    padding-top: 50px;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
}

.main-footer {
    height: 50px;
    background: #00b3e3;
    padding: 0;
    width: auto;
    color: white;
    border-top: none;
    min-width: 1400px;
    margin-left: 0px;
    display: flex;
    justify-content: space-between;
}

.main-footer span {
    line-height: 50px;
    margin-bottom: 0px;
    font-family: Barlow;
}

.main-footer .footer__disclaimer {
    margin-left: auto;
    margin-right: auto;
}

.main-footer .footer__version {
    margin-right: 10px;
}

.main-menu {
    margin-top: 50px;
}

.logo-toggle-on {
    transition: width 0.5s ease !important;
    width: 230px !important;
}

.logo-toggle-on {
    transition: width 0.5s ease !important;
}

.logo-toggle-off {
    padding: 0px !important;
    width: 44px !important;
    transition: width 0.5s ease !important;
}

@media (max-width: 764px) {
    .main-header .logo,
    #sidebar-treeview {
        padding: 0px !important;
        width: 44px !important;
        transition: width 0.5s ease !important;
    }

    .e-icons.e-icon-expandable.interaction {
        visibility: hidden !important;
        transition: visibility 0.5s;
    }

    #header-section.main-header #hamburger {
        position: fixed;
        left: 44px !important;
        transition: left 0.5s ease !important;
    }

    .e-content-animation {
        margin-left: 44px !important;
        transition: margin 0.5s ease;
    }
}

.dark-blue-btn {
    background-color: #0a5b88;
    color: #fff;
}

ul.circle {
    list-style-type: circle;
}

ul.lower-alpha {
    list-style-type: lower-alpha;
}

.blue-reactstrap-popover {
    border: 1px solid #0a5b88;
}

.blue-reactstrap-popover .popover-header {
    background-color: #d6f4fc;
}

.hamburger-toggle-on {
    position: fixed;
    left: 230px;
    transition: left 0.5s ease !important;
}

.hamburger-toggle-off {
    position: fixed;
    left: 44px;
    transition: left 0.5s ease !important;
}

.e-sidebar.e-left.e-close.e-dock {
    transition: width 0.5s ease !important;
}

.e-sidebar.e-left.e-open {
    transition: width 0.5s ease !important;
}

.e-toolbar-item.nonClosableTab .e-tab-wrap .e-close-icon[title='Close'] {
    display: none;
}

.e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
    margin-right: 3px;
}

.inactive > .e-tab-wrap {
    background-color: #eee !important;
}

.e-active > .e-tab-wrap {
    background-color: rgb(0, 179, 227) !important;
}

.inactivemember > .e-tab-wrap {
    background-color: #808080 !important;
}

.inactivemember .e-tab-wrap .e-tab-text {
    color: #ffffff !important;
}

.grid-panel .e-tab-wrap {
    background-color: #0099a8;
}

.grid-panel .e-active > .e-tab-wrap {
    background-color: #fff !important;
    color: black;
}

.e-tab .e-tab-header .e-toolbar-item.inactive .e-tab-wrap {
    background-color: #d4d4d4 !important;
}

.e-tab .e-tab-header .e-toolbar-item.inactive:not(.e-active) .e-tab-wrap .e-tab-text {
    color: rgba(0, 0, 0, 0.54) !important;
}

.e-tab .e-tab-header .e-toolbar-item.e-active.inactive .e-tab-wrap {
    background-color: #808080 !important;
}

.e-tab .e-tab-header .e-toolbar-item.e-active.term-date .e-tab-wrap {
    background-color: #ff5050 !important;
}

.e-tab .e-tab-header .e-toolbar-item.e-active.frequent-caller .e-tab-wrap {
    background-color: #fd7e14 !important;
}

.e-tab .e-tab-header .e-toolbar-item.e-active.new-member .e-tab-wrap {
    background-color: #05ad0d !important;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon,
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
    color: #00b3e3;
    text-transform: none;
}

.e-tab .e-tab-header .e-toolbar-item.inactive .e-tab-wrap .e-tab-text,
.e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-tab-icon,
.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
    color: #ffffff;
    text-transform: none;
}

.e-tab-wrap {
    background-color: #ffffff;
}

.e-tab-wrap,
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap,
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
    border: solid 1px;
    border-bottom-width: 0px;
    border-color: rgb(0, 179, 227);
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
    background-color: #ecf0f5;
}

.inactive .e-tab-wrap {
    border-color: #808080;
}

.e-indicator {
    display: none !important;
}

.tabDiv {
    border: solid 1px rgb(0, 179, 227);
    background-color: white;
}

.tabDiv.ProcessedGapsReviewTab {
    min-height: 710px;
}

.pageMsg {
    display: flex;
    flex: 90%;
    align-items: center;
    font-family: 'Segoe UI', 'GeezaPro', 'DejaVu Serif', sans-serif;
    padding: 10px 10px 10px 20px;
    color: #1675a9;
    font-size: 13px;
    opacity: 0.95;
    background-color: #ffffff;
}

.actionNotes {
    width: 100%;
    line-height: 20px;
    padding: 10px;
    resize: none;
}

#_dialog-content {
    padding-top: 20px;
}

.hccAlertDialogContent .attachedFileList #_dialog-content {
    padding: 0 !important;
    /* This needed the important property as
        it could not be achieved only with specificity because there is
        another rule that have !important on Line 2448
    */
    line-height: 0;
}

.dateWithAppointment span.e-day {
    background: #eee;
    border: 1px solid #163ae3 !important;
    border-radius: 50% !important;
    color: #163ae3;
}

#filters-panel {
    margin: 10px;
    width: auto;
    display: flex;
}

#filters-container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
}

#filter-selector {
    margin-right: 10px;
}

.filter-controls {
    display: flex;
    flex-shrink: 0;
    margin-left: 25px;
}

#page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#page-header-text {
    font-size: 32px;
    color: #1675a9;
}

.statButtonWrapper {
    display: inline-block;
    width: calc(100% / 6);
    text-align: center;
    padding: 10px 20px 10px 20px;
}

.statButtonWrapper.census {
    width: calc(100% / 7);
}

.statButton {
    vertical-align: top;
    width: 100%;
    height: 116px;
    border: solid 1px rgb(0, 179, 227);
    background-color: #f3fbfe;
    padding: 5px 20px 10px;
}

.statButton-selected {
    background-image: radial-gradient(#fff, rgb(122, 212, 234)) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.statButtonWarning {
    background-image: radial-gradient(#fff, rgba(255, 240, 187, 255));
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.statButtonValue {
    color: #1675a9;
    font-size: 30px;
}

.statButtonValueWarning {
    color: #d75428;
}

.statButtonTitle,
.statButtonAbbreviation {
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.e-dlg-header {
    color: white;
}

.e-dlg-header-content {
    background-color: #00b3e3;
}

.e-icon-dlg-close::before {
    color: white;
}

.memberPageButton {
    width: 75px;
}

.dailyAppointmentsListDiv {
    width: 700px !important;
}

.memberInfoDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 200px;
}

.memberInfo2Div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 300px;
}

/*Grid column sort button */

.e-grid .e-sortfilterdiv {
    margin-left: auto;
    margin-right: auto;
}

/*PDf Export Button Animation Controller*/

.exportPDFBtn {
    width: 54px;
    height: 54px;
    padding: 12px 12px 12px;
    margin-right: 20px;
    border: 0;
    border-radius: 3px;
    background: transparent;
    transition: all 1.2s ease-in-out;
}

.exportPDFBtn:focus {
    outline: 0 !important;
}

.exportPDFBtn:disabled i::before {
    color: #93cbe9;
}

.exportPDFBtn:hover:enabled {
    animation: zoom 0.2s 1 both;
}

.exportPDFBtn.loading {
    pointer-events: none;
    animation: loading 2s linear infinite;
}

.exportPDFBtn.loading i::before {
    display: inline;
    content: '\e99d';
}

.exportPDFBtn.success {
    border-radius: 50%;
    background: #00b3e3;
}

.exportPDFBtn.success i::before {
    display: inline;
    content: '\e7ff';
    animation: change-icon 1s 0.6s linear both;
}

@keyframes change-icon {
    0% {
        color: transparent;
    }

    100% {
        color: #fff;
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes zoom {
    from {
        transform: scale3d(1, 1, 1);
    }

    to {
        transform: scale3d(1.1, 1.1, 1.1);
    }
}

.editJSA .e-round.e-primary.e-icon-btn {
    height: 30px;
    width: 30px;
    margin-top: 0px !important;
}

.editJSA .e-btn-icon.e-icons.e-close-icon {
    line-height: 1em;
}

/*Icon buttons*/

.e-pdfBtn::before {
    content: '\e240';
    color: #1675a9;
    font-size: 30px;
}

.e-close-icon::before {
    content: '\e7fc';
}

.exportPDFBtn:disabled .e-pdfBtn::before {
    cursor: not-allowed;
}

.e-editBtn::before {
    font-size: 18px;
    content: '\e7a3';
    cursor: pointer;
}

.e-checkMark::before {
    content: '\e7ff';
}

.e-dot {
    height: 10px !important;
    width: 10px !important;
    min-width: 10px !important;
    background-color: #28a745;
    border-radius: 50%;
    margin-left: -10px;
    position: relative;
    left: -6px;
}

.e-comment::before {
    content: '\ec10';
}

.editJSAInput .jsaTabComponent .e-icons.e-tab-icon.e-icon-right.checkMarkNotDisplay {
    display: none;
}

/*Edit JSA Section Check Mark Color*/

.editJSAInput .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon,
.editJSAInput .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-tab-icon,
.editJSAInput .jsaTabComponent .e-icons.e-tab-icon.e-icon-right.e-checkMark {
    color: #28a745;
}

.jsaEditBtnIcon,
.jsaPdfBtnIcon,
.jsaDeleteBtnIcon,
.jsaAppointmentDetailsIcon {
    font-size: 18px;
    cursor: pointer;
}

.e-viewDetailBtn::before {
    font-size: 18px;
    content: '\e31d';
    cursor: pointer;
}

.e-uploadDocsBtn::before :enabled {
    font-size: 18px;
    content: '\e60f';
    cursor: pointer;
}

.e-save-icon::before {
    content: '\e735';
}

.e-print-icon::before {
    content: '\e813';
}

.e-cancel-icon::before {
    content: '\e7a7';
}

.e-delete-icon::before {
    content: '\ec1c';
}

.e-ok::before {
    content: '\ea84';
}

.e-cross-close::before {
    content: '\e7e9';
}

/*END Syncfusion Icons*/

.e-grid-min-height .e-rowcell {
    padding-top: 8px;
    padding-bottom: 8px;
}

/*
    Grid Style
*/

/*Grid Title Div For position of text and button in title*/

.gridHeaderDiv {
    display: flex;
    padding: 5px 10px 5px 15px;
    background-color: #eefbff;
    border-bottom: solid 1px #60bed7;
    max-height: 50px;
}

/*Grid Title text*/

.gridTitle {
    flex: 1;
    font-size: 15px;
    font-weight: 700;
    color: #1675a9;
    margin: 0px;
    line-height: 30px;
}

/*Grid Title Button*/

.gridHeaderButton {
    background-color: #009aa8 !important;
    margin-left: 10px;
}

.gridHeaderButton:disabled {
    background-color: #77cad3 !important;
    color: #edf8fa !important;
}

.gridHeaderButton.goToP360Button {
    background-color: #e3165b !important;
}

/*Grid Wrapper for each grids*/

.grid-wrapper {
    border: solid 1px;
    border-color: rgb(0, 192, 239);
    background-color: white;
    margin-bottom: 15px;
    margin-top: 15px;
}

.grid-wrapper.PanelManagement {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

/*Gird Hover Icon Button Disappeared When is not Hovered */

.e-row .e-icon-btn,
.e-editBtn,
.e-viewDetailBtn,
.e-uploadDocsBtn,
.delete-icon,
.jsaEditBtnIcon,
.jsaDeleteBtnIcon,
.jsaAppointmentDetailsIcon {
    display: none;
}

/*Completed JSA Owner should always see the edit icon button*/

span .jsaEditBtnIcon {
    display: inline;
}

/*Make Grid Button Hover to display*/

.grid-panel .e-row .e-icon-btn,
.grid-panel .e-row .e-editBtn,
.grid-panel .e-row .e-viewDetailBtn,
.grid-panel .e-row .e-uploadDocsBtn,
.e-row:hover .e-icon-btn,
.e-row:hover .e-editBtn,
.e-row:hover .e-viewDetailBtn,
.e-row:hover .e-uploadDocsBtn,
.e-row:hover .jsaEditBtnIcon,
.e-row:hover .jsaPdfBtnIcon,
.e-row:hover .jsaDeleteBtnIcon,
.e-row:hover .jsaAppointmentDetailsIcon,
.btn-box-tool > i {
    display: inline;
}

/*Grid Hover Icon Buttons Position*/

.e-rowcell.e-templatecell {
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle !important;
}

/*Grid Hover Color hightlight*/

.e-row:hover {
    background-color: rgba(0, 192, 239, 0.14);
}

/*Grid Toolbar Export Excel Button Animation Controller*/

.animatedBtn {
    border-radius: 1px;
    background: transparent;
    transition: all 1.2s ease-in-out;
}

.loading .e-btn-icon {
    animation: loading 2s linear infinite;
}

.loading .e-btn-icon::before {
    content: '\e99d';
}

.success .e-btn-icon {
    border-radius: 50%;
    background: #00b3e3;
}

.success .e-btn-icon::before {
    content: '\e7ff';
    animation: change-icon 1s 0.6s linear both;
}

/*Grid Toolbar background color*/

.e-toolbar {
    background-color: #eefbff;
}

/*Grid header check box*/

.e-grid-min-height .e-gridheader .e-headercelldiv.e-headerchkcelldiv,
.e-grid .e-gridheader .e-headercontent .e-centeralign .e-headercelldiv.e-headerchkcelldiv {
    height: 20px;
    padding: 0px;
    line-height: 18px;
}

/*Detailed Gap View Dialo*/

/*Gap Detail View Docs List Cursor Moveover Style*/

.docFileName {
    cursor: pointer;
    font-size: 13px;
}

.longDocFileName {
    cursor: pointer;
    font-size: 13px;
    width: 200px;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.longDocFileName:hover {
    overflow: visible;
    background-color: #ffff;
    position: absolute;
    z-index: 99999;
    min-width: 200px;
    width: auto;
    max-width: fit-content !important;
    padding-right: 10px;
}

/*Docs List Hover Delete Button*/

.e-list-item:hover .delete-icon {
    display: inline;
}

.e-listview.statusHistoryList .e-content ul li,
.e-listview.attchmentListViewComponent .e-content ul li {
    line-height: 25px;
    height: 25px;
}

/* .e-listview.attchmentListViewComponent .e-content .e-ul {
    height: calc(100% + 25px);
    z-index: 1000;
} */

/*Detailed Gap View Dialog END*/

/*Year Chooser Div*/

.yearSelectorDiv {
    display: flex;
    flex: 10%;
    align-items: center;
    background-color: white;
    padding: 10px;
}

/*YearDropDown text jumping*/

.e-dropdownlist {
    padding: 0px 10px 0px 10px !important;
    margin-bottom: 0px !important;
}

/**/

.e-tab .e-tab-header .e-toolbar-item.e-active .e-close-icon {
    color: #fff;
}

/**/

.e-search-icon {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    margin-right: 4px !important;
}

/**/

.custom-rolling {
    position: relative;
    -webkit-transform: translate(-31px, -31px) scale(0.31) translate(31px, 31px);
    height: 62px !important;
    transform: translate(-31px, -31px) scale(0.31) translate(31px, 31px);
    width: 62px !important;
}

.custom-rolling div,
.custom-rolling div:after {
    border: 12px solid gray;
    border-radius: 50%;
    border-top-color: transparent;
    height: 100px;
    position: absolute;
    width: 100px;
}

.custom-rolling div {
    -webkit-animation: custom-rolling 1.3s linear infinite;
    animation: custom-rolling 1.3s linear infinite;
    top: 100px;
    left: 100px;
}

.custom-rolling div:after {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

/**/

@keyframes custom-rolling {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@-webkit-keyframes custom-rolling {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg);
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg);
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

/*vertical align placeholder text*/

#dateTimePickerWrapper,
#providerName {
    padding: 6px 0px;
}

#homePage {
    padding: 50px;
}

#homePage .tile-text {
    font-size: 20px;
    margin-top: 10px;
    color: rgb(52, 118, 164);
}

#homePage .home-tile-container {
    width: 300px;
    height: 300px;
    display: inline-block;
    text-align: center;
}

#homePage .home-tile {
    width: 200px;
    height: 200px;
    display: inline-block;
    padding: 40px;
    text-align: center;
    vertical-align: middle;
    background: linear-gradient(rgb(52, 118, 164), rgb(69, 150, 166));
    border-radius: 40px;
}

#homePage .home-tile:hover {
    cursor: pointer;
}

#homePage .home-tile:first-child {
    margin-left: 0;
}

#homePage a {
    color: #0099a8;
}

#homePage a:hover i svg {
    fill: #02bdce;
}

#homePage a:hover {
    color: #02bdce;
}

#homePage .icon-title h2 {
    font-size: 28px;
    margin-top: 30px;
    font-weight: 400;
}

/****Nav Icons****/

.icon-sm {
    margin-right: 5px;
    margin-left: 1px;
}

.icon-sm svg {
    display: inline;
    height: 14px;
    width: 20px;
    margin: 0 3px 0 -3px;
    fill: #b8c7ce;
}

.icon-xl svg {
    display: inline;
    height: 90%;
    width: 90%;
    fill: white;
    vertical-align: middle;
}

a.home-tile {
    color: white;
    text-decoration: none;
}

a.home-icon div {
    margin-top: 10px;
}

h4 {
    font-size: 16px;
    margin: 0;
    font-weight: 400;
}

#pdfListView h4 {
    font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif', '-apple-system', 'BlinkMacSystemFont';
    font-weight: 700;
    margin-left: 50px;
    margin-top: 30px;
}

#pdfListView h5 {
    font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif', '-apple-system', 'BlinkMacSystemFont';
    font-weight: 700;
    margin-left: 80px !important;
    margin-top: 20px;
    font-size: 14px;
}

#pdfListView ol {
    margin-left: 50px;
    list-style-type: disc;
    color: #1675a9;
    font-size: 15px;
    font-weight: 500;
    padding-left: 50px;
}

#pdfListView ol li {
    list-style: disc;
    line-height: 2.5em;
}

#pdfListView ol li span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.box.box-solid.box-info > .box-header.secondary {
    background-color: #d6f4fc;
    color: #0a5b88;
}

.box-header {
    background-color: #00b3e3;
    color: #fff;
    display: block;
    padding: 10px;
    position: relative;
}

.tag-box {
    background-color: #d6f4fc;
    padding: 5px;
    border-top: 1px solid rgb(0, 179, 227);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ahc-tag {
    border-radius: 5px;
    color: #03659a;
    background-color: #e2f8fe;
    border: 1px solid #3c8dbc;
    padding: 4px 12px 3px 12px;
    display: inline-block;
    font-size: 14px;
    margin: 5px;
}

.ahc-tag-no-margin {
    border-radius: 5px;
    color: #03659a;
    background-color: #e2f8fe;
    border: 1px solid #3c8dbc;
    padding: 4px 12px 3px 12px;
    display: inline-block;
    font-size: 14px;
}

.ahc-tag-no-margin.green {
    background-color: #05ad0d;
    color: #fff;
    border-color: #05ad0d;
}

.ahc-tag b {
    font-weight: 500;
}

.ahc-tag.red {
    background-color: #ff5050;
    color: #fff;
    border-color: #ff5050;
}

.ahc-tag.purple {
    background-color: #6f42c1;
    color: #fff;
    border-color: #6f42c1;
}

.ahc-tag.green {
    background-color: #05ad0d;
    color: #fff;
    border-color: #05ad0d;
}

.ahc-tag.yellow {
    background-color: #f16d09;
    color: #fff;
    border-color: #f16d09;
}

.ahc-tag.redOutline {
    background-color: #fff;
    color: #ff5050;
    border-color: #ff5050;
}

.ahc-tag.greenOutline {
    background-color: #fff;
    color: #05ad0d;
    border-color: #05ad0d;
}

.ahc-tag.yellowOutline {
    background-color: #fff;
    color: #f16d09;
    border-color: #f16d09;
}

.ahc-tag.gray {
    background-color: #f3f3f3;
    color: rgba(0, 0, 0, 0.42);
    border-color: rgba(0, 0, 0, 0.42);
}

.table-user-information td {
    border-bottom: 2px solid #d6f4fc;
}

/*Member Card*/

.member-card-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: rgb(0, 179, 227);
    color: white;
    margin: 0;
}

.member-card-header.inactive {
    background-color: #808080;
}

.member-card-header.term-date {
    background-color: #ff5050 !important;
}

.member-card-header.new-member {
    background-color: #05ad0d !important;
}

.member-card-header.frequent-caller {
    background-color: #fd7e14 !important;
}

.member-header-button:focus,
.add-note-button:focus {
    color: darkcyan;
    background-color: white;
}

.member-header-button:hover,
.add-note-button:hover {
    background-color: lightgray;
}

.member-header-button:active,
.add-note-button:active {
    color: black;
    background-color: grey;
}

.member-header-button,
.add-note-button {
    color: darkcyan;
    border-radius: 8px;
    margin-right: 10px;
}

.member-card-header.inactive .gridHeaderDiv {
    background-color: #808080;
    border: none;
}

.member-card-header.term-date .gridHeaderDiv {
    background-color: #ff5050;
    border: none;
}

.member-card-header.frequent-caller .gridHeaderDiv {
    background-color: #fd7e14;
    border: none;
}

.member-card-header.new-member .gridHeaderDiv {
    background-color: #05ad0d;
    border: none;
}

.member-card-header .gridHeaderDiv {
    border: none;
    padding: 5px 10px 5px 15px;
}

.inactiveMember .e-tab-wrap .e-text-wrap .e-tab-text {
    color: #808080 !important;
}

.inactiveMember .e-tab-wrap {
    border-color: #808080;
}

.inactiveMember.e-active .e-tab-wrap {
    background-color: #808080 !important;
    border-color: #808080;
}

.inactiveMember.e-active .e-tab-wrap .e-tab-text {
    background-color: #808080 !important;
    border-color: #808080 !important;
    color: #fff !important;
}

.patientEligibilityProfileTab > .e-toolbar > .e-toolbar-items > .e-toolbar-item {
    width: 25%;
}

.card-title-item {
    margin-right: 40px;
}

.card-table-item {
    margin-right: 80px;
    vertical-align: top;
}

.member-card-header-info {
    display: flex;
    flex-direction: row;
    padding: 4px 0;
    align-items: center;
    font-size: 14px;
}

.member-termed-card-header-info {
    display: flex;
    flex-direction: row;
    padding: 4px 0;
    align-items: center;
    font-size: 14px;
    background-color: #808080;
}

.member-card-content,
.membership-card-content {
    padding: 15px;
    color: #03659a;
    background-color: #eefbff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.membership-card-content {
    background-color: #fff;
}

.member-card-content > div {
    width: 28%;
    margin: 0px 20px;
}

.member-card-content.um-fax > div {
    overflow: auto;
}

.member-card-content-col div {
    padding: 10px;
    border-top: solid 2px rgb(186, 235, 249);
    white-space: pre;
    font-size: 14px;
}

.member-card-content-col div:last-child {
    border-bottom: solid 2px rgb(186, 235, 249);
}

.member-card-content .member-card-profile {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 180px;
}

.profile-image {
    height: 115px;
    border: 1px solid rgb(186, 235, 249);
    background: white;
    border-radius: 50%;
}

/*End of Member Card*/

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.timeline-container {
    position: relative;
    max-width: 98%;
    margin: 0 auto;
}

.timeline-nav {
    position: absolute;
    top: 55px;
    bottom: auto;
    color: #777;
    border: 2px solid #777;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    vertical-align: middle;
}

.timeline-nav:hover {
    color: #005a88;
    border: 2px solid #0a5b88;
    cursor: pointer;
}

.nav-future {
    margin-top: 35px;
    right: 10px;
}

.nav-past {
    margin-top: 35px;
    left: 10px;
}

.timeline-nav.inactive {
    color: #dfdfdf;
    border: 2px solid #dfdfdf;
}

.timeline-nav.inactive:hover {
    cursor: default;
}

.timeline-wrapper {
    position: relative;
    height: 100%;
    margin: 0 45px;
    overflow: hidden;
}

.timeline-wrapper::before,
.timeline-wrapper::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px;
}

.event-timeline {
    position: absolute;
    z-index: 1;
    top: 69px;
    min-width: 900px;
    height: 2px;
    background-color: #555;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.event-timeline svg {
    overflow: visible;
    position: absolute;
    top: -40px;
}

.event-marker {
    position: absolute;
    top: -6px;
    width: 10px;
    height: 10px;
    background-color: #f8f8f8;
    border: 2px solid #dfdfdf;
    border-radius: 50%;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.event-marker.selected {
    transform: scale(1.2);
    background-color: #808;
    border: 2px solid #808;
}

.event-marker:hover {
    transform: scale(1.2);
    background-color: #808;
    border: 2px solid #808;
}

.event-date {
    position: relative;
    bottom: 25px;
    width: 30px;
}

.timeline-tick {
    position: absolute;
    top: 0;
    width: 2px;
    height: 7px;
    background-color: #555;
}

.tick-label {
    position: absolute;
    top: 25px;
    font-size: 7pt;
    font-weight: bold;
}

.event-detail-container {
    min-height: 150px;
    background-color: #f8f8f8;
    padding: 20px;
}

.selected-event {
    width: 60%;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 12px;
}

.event-title {
    font-family: 'Georgia', serif;
    font-size: 20px;
}

.event-date-range {
    margin-top: 10px;
}

.event-start-date {
    display: inline-block;
}

.separator {
    display: inline-block;
}

.event-end-date {
    display: inline-block;
}

.event-notes {
    margin-top: 10px;
}

.control-section.row {
    margin-bottom: 15px;
}

.e-toolbar {
    background-color: #eefbff;
}

.e-toolbar-item .e-tbar-btn-text {
    color: #1675a9 !important;
    font-weight: bold;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.toolbar-grid-title.e-overlay {
    opacity: 1;
    background: #eefbff;
}

.e-toolbar .e-tbar-btn {
    background: #eefbff;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.87);
}

.e-input-group.e-search {
    background-color: #afdde9;
    padding-left: 5px;
    border: solid;
    border-width: 1px;
    border-color: #80c5d7 !important;
    opacity: 1 !important;
}

.e-input-group.e-search input::placeholder,
.e-input-group.e-search .e-search-icon {
    color: #00565f;
    font-weight: 600;
    font-size: 13px;
}

.e-input-group.e-search:focus-within {
    background-color: white;
}

.e-input-group.e-search input:focus::placeholder,
.e-input-group.e-search:focus-within .e-search-icon {
    color: rgba(0, 0, 0, 0.46);
    font-weight: initial;
}

.e-toolbar .e-toolbar-items {
    background: #eefbff;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(238, 251, 255);
}

.four-tab.grid-panel .e-tab-wrap,
.grid-panel .patient-tabs .e-tab-wrap {
    background-color: #0099a8;
}

.grid-panel .patient-tabs .e-tab .e-tab-header .e-toolbar-item .e-tab-text,
.grid-panel .patient-tabs .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text {
    background-color: #0099a8;
    color: white;
}

.four-tab.grid-panel .e-tab .e-tab-header .e-toolbar-item .e-tab-text,
.four-tab.grid-panel .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text {
    background-color: #0099a8;
    color: white;
    min-width: 16vw !important;
    width: 16vw !important;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
}

.four-tab.grid-panel .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.grid-panel .patient-tabs .e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
    background-color: white;
    color: #0099a8;
    justify-content: center;
}

.four-tab.grid-panel .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.grid-panel .patient-tabs .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text {
    background-color: white;
    color: #0099a8;
}

.grid-panel .e-item.e-active,
.grid-panel .e-item.e-active {
    padding: 10px;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) {
    border-radius: 5px;
}

.e-float-input:not(.e-error) input:valid ~ label.e-float-text {
    margin-top: -5px;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    border: 1px solid;
    border-width: 1px 1px 1px 1px;
    padding-left: 5px;
}

.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
.e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
.e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error)
    input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error)
    input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error)
    input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error
    input,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error)
    textarea,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error)
    textarea,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error)
    textarea,
.e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error
    textarea,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error)
    input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error)
    input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error)
    input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error
    input,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning):not(.e-error)
    textarea,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled).e-success:not(.e-warning):not(.e-error)
    textarea,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success).e-warning:not(.e-error)
    textarea,
.e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-disabled):not(.e-success):not(.e-warning).e-error
    textarea,
.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-input-group.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
.e-input-group.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
.e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.e-float-icon-left.e-input-focus.e-success:not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.e-float-icon-left.e-input-focus.e-warning:not(.e-success):not(.e-error) .e-input-in-wrap,
.e-input-group.e-float-icon-left.e-input-focus.e-error:not(.e-success):not(.e-warning) .e-input-in-wrap {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    padding-left: 5px;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: none;
    margin-bottom: 15px;
}

.e-tab .e-content .e-item.e-active {
    border-top: solid 4px rgb(0, 179, 227);
}

.e-item.e-active:has(div):has(div):has(div):has(div):has(.term-date) {
    border-top: solid 4px #ff5050 !important;
}

.e-item.e-active:has(div):has(div):has(div):has(div):has(.new-member) {
    border-top: solid 4px #05ad0d !important;
}

.e-item.e-active:has(div):has(div):has(div):has(div):has(.frequent-caller) {
    border-top: solid 4px #fd7e14 !important;
}

.e-item.e-active:has(div):has(div):has(div):has(div):has(.inactive) {
    border-top: solid 4px #808080 !important;
}

.grid-panel .e-tab .e-content .e-item.e-active {
    border-top: none;
}

.e-tab .e-toolbar-item.inactive .e-content .e-active {
    border-color: #808080;
}

.grid-panel .e-grid {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    border: none;
}

.grid-panel .e-grid:hover {
    border: 1px #8ed2e5 solid;
    margin: -1px;
}

.grid-panel .e-tab .e-tab-header {
    border-bottom: none;
}

.e-headertext {
    color: black;
    font-weight: bold;
}

.e-input-group.e-control-wrapper {
    padding-left: 5px;
}

.e-accordion {
    margin-bottom: 20px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content {
    padding: 0px;
}

button#btnAdvancedMemberSearch {
    height: 37px;
}

.btn.btn-flat {
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-width: 1px;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 4px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.box .e-grid {
    overflow: hidden;
}

.e-popup .e-checkbox-wrapper {
    padding-left: 0 !important;
}

.btn-primary {
    background-color: #3c8dbc !important;
}

.btn-primary:hover {
    background-color: #367fa9 !important;
    border-color: #204d74 !important;
}

.btn-primary:active {
    background-color: #286090 !important;
}

/*advanced search dynamic filter style*/

.e-multiselect.e-input-group.e-control-wrapper.e-checkbox.advancedSearchDynamicFilterContainer,
.e-multiselect.e-input-group.e-control-wrapper.e-checkbox.advancedSearchDynamicFilterContainer input::placeholder,
.advancedSearchDynamicFilterContainer .e-multi-select-wrapper .e-delim-values .e-remain {
    background-color: rgb(0, 179, 227);
    color: white;
}

.advancedSearchGridTemplate .e-gridpager.e-control.e-pager.e-lib.e-pagertemplate {
    text-align: right;
}

.advancedSearchGridTemplate
    .e-gridpager.e-control.e-pager.e-lib.e-pagertemplate
    .e-control-wrapper.e-input-group.e-numeric.e-valid-input {
    border: none;
    border-bottom: solid 1px gray;
    border-radius: 0;
}

.e-multiselect.e-input-group.e-control-wrapper.e-checkbox.advancedSearchDynamicFilterContainer {
    margin-bottom: 10px;
}

div[style*='float: left;'] .e-dropdownbase::placeholder,
div[style*='float: left;'] .e-delim-view.e-delim-values,
div[style*='float: left;'] .e-chips-close.e-close-hooker,
div[style*='float: left;'] .e-input-group-icon.e-ddl-icon,
div[style*='float: left;'] .e-input-group-icon.e-ddl-icon:hover {
    color: white;
}

.e-ccdlg .e-dlg-header-content {
    display: none;
}

.e-ccdlg .e-dlg-content {
    margin-top: 0 !important;
    padding: 0 !important;
}

.e-ccdlg .e-main-div {
    padding-top: 40px;
}

.e-ccdlg .e-cc-searchdiv {
    top: 0 !important;
    left: 0 !important;
    padding: 0 !important;
    border: 1px solid !important;
}

.top-tab-div {
    border-top: solid 4px rgb(0, 179, 227) !important;
}

.tab-div {
    border: solid 1px rgb(0, 179, 227);
    background-color: white;
}

.page-msg {
    display: flex;
    align-items: center;
    font-family: 'Segoe UI', 'GeezaPro', 'DejaVu Serif', sans-serif;
    padding: 10px;
    color: #1675a9;
    font-size: 13px;
    opacity: 0.95;
    background-color: #ffffff;
}

.year-selector-div {
    display: flex;
    flex: 10%;
    align-items: center;
    background-color: white;
    padding: 10px;
}

.exportPDFBtn {
    width: 54px;
    height: 54px;
    padding: 12px 12px 12px;
    margin-right: 20px;
    border: 0;
    border-radius: 3px;
    background: transparent;
    transition: all 1.2s ease-in-out;
}

.exportPDFBtn:focus {
    outline: 0 !important;
}

.exportPDFBtn:disabled i::before {
    color: #93cbe9;
}

.exportPDFBtn:hover:enabled {
    animation: zoom 0.2s 1 both;
}

.exportPDFBtn.loading {
    animation: loading 2s linear infinite;
}

.exportPDFBtn.loading i::before {
    display: inline;
    content: '\e99d';
}

.exportPDFBtn.success {
    border-radius: 50%;
    background: #00b3e3;
}

.exportPDFBtn.success i::before {
    display: inline;
    content: '\e7ff';
    animation: change-icon 1s 0.6s linear both;
}

.grid-wrapper {
    border: solid 1px;
    border-color: rgb(0, 192, 239);
    margin-bottom: 15px;
    margin-top: 15px;
}

.box-header-button i {
    color: #1675a9;
}

.box-header-button svg {
    fill: white;
    height: 20px;
}

.popup-window .e-dlg-header *,
.dark .box-header-button i {
    color: #fff;
}

.e-dlg-header .box-header-button {
    float: right;
}

.e-dlg-header .box-header-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.gridHeaderButton {
    background-color: #009aa8 !important;
}

.e-editBtn::before {
    font-size: 18px;
    content: '\e7a3';
}

.e-viewDetailBtn::before {
    font-size: 18px;
    content: '\e31d';
}

.e-uploadDocsBtn::before {
    font-size: 18px;
    content: '\e60f';
}

.detailedGapInfoTable tr td:first-child {
    font-weight: 500;
}

.detailedGapInfoTable td {
    padding: 8px 8px 8px 0px;
}

.e-dlg-header {
    color: white;
}

.actionNotes {
    width: 100%;
    line-height: 20px;
    padding: 10px;
    resize: none;
}

.exportPDFBtn.loading {
    animation: loading 2s linear infinite;
}

.exportPDFBtn.loading i::before {
    display: inline;
    content: '\e99d';
}

.e-treeview.e-fullrow-wrap .e-icon-expandable {
    transform: rotate(180deg);
}

.e-treeview .e-icons.interaction {
    color: white;
    float: right;
    margin-right: 5px;
}

.e-close .e-treeview .e-icons.interaction {
    display: none;
}

/*Grid Toolbar Export Excel Button Animation Controller*/

.animatedBtn {
    border-radius: 1px;
    background: transparent;
    transition: all 1.2s ease-in-out;
}

.loading .e-btn-icon {
    animation: loading 2s linear infinite;
}

.loading .e-btn-icon::before {
    content: '\e99d';
}

.success .e-btn-icon {
    border-radius: 50%;
    background: #00b3e3;
}

.success .e-btn-icon::before {
    content: '\e7ff';
    animation: change-icon 1s 0.6s linear both;
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.memberSearchBtn {
    height: 40px;
    width: 40px;
    font-size: 20px;
    color: #fff;
    background-color: rgb(0, 153, 168);
    border-width: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 11px;
}

.memberSearchBtn span {
    margin-left: -4px;
    margin-bottom: 6px;
    text-align: center;
    vertical-align: bottom;
}

.searchRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

#main-search-tab .searchRow {
    margin: 20px;
}

#single-search-tab .searchRow {
    width: 400px;
    padding: 10px;
}

#main-search-tab {
    width: 100%;
    height: calc(100vh - 220px);
    min-height: 640px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#eligibilityRequestButton,
#authorizationRequestButton {
    border-radius: 10px;
    background-color: #0099a8;
    color: #fff;
    font-weight: normal;
}

.avaButton {
    border-radius: 10px;
    background-color: #0099a8;
    color: #fff;
    font-weight: normal;
    margin-right: 5px;
}

.avaButton.danger {
    background-color: #ff5050;
}

.searchTextBox {
    width: 500px;
}

.searchTextBox span {
    height: 40px;
}

#mainSearchBar .searchTextBox span {
    font-size: 16px !important;
}

#mainSearchBar .searchRow .searchTextBox .e-input-group.e-control-wrapper.e-ddl {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 10px;
}

.summaryCounter {
    border: 1px solid #00c0ef;
    height: 150px;
    background-color: white;
    text-align: center;
    padding: 37px 0;
}

.summaryCounter .indicesNumber {
    color: #1675a9;
    font-size: 30px;
}

.summaryCounter .header {
    font-size: 18px;
}

/*PP Dashboard Gauges*/

.gauge-container {
    width: 100%;
    background-color: white;
}

.gauge-wrapper {
    width: 33%;
    display: inline-block;
}

.gaugeIndex-wrapper {
    width: 33%;
    text-align: center;
    margin-right: 40px;
    margin-left: 40px;
    font-size: 20px;
    font-weight: 700;
}

/*End of PP Dashboard Gauges*/

.rating .star {
    font-size: 132%;
    color: lightgrey;
}

.rating .star.checked {
    color: #ffa600;
}

.rating .star:before {
    content: '★';
}

.circle-value {
    font-size: 18px;
    width: 120px;
    text-align: center;
    margin: 25px;
}

.circle-value span {
    font-weight: bold;
    font-size: 30px;
}

.circle-text {
    height: 70px;
    width: 250px;
    text-align: center;
    font-weight: 500;
}

.box.box-solid.box-info {
    border: 1px solid rgb(0, 179, 227);
    border-radius: unset;
    margin-bottom: 15px;
}

.box {
    position: relative;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 0px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

h4 {
    font-size: 16px;
    margin: 0;
}

.bold {
    font-weight: 600;
}

.score-section {
    padding: 10px;
}

.circle-charts {
    padding: 25px 0;
}

.circle-charts h4 {
    color: dimgray;
    text-align: center;
}

.guage {
    width: 200px;
    height: 200px;
    margin: auto;
}

#btnSearch {
    display: flex;
    justify-content: center;
    align-items: center;
}

.exportPDFBtn .badge {
    position: absolute;
    top: 70px;
    right: 70px;
}

.exportPDFBtn.loading .badge {
    display: none;
}

.exportPDFBtn:hover .badge {
    top: 5px;
    right: 35px;
}

.exportPDFBtn .badge-dark {
    background-color: #000000;
    padding: 4px;
}

.e-row button.e-icon-btn {
    border: none;
    box-shadow: none;
    background: none;
}

.grid-panel .e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
    text-align: center;
    display: table-cell;
}

.grid-panel .e-tab .e-tab-header .e-toolbar-item.e-separator {
    width: 0;
}

.grid-panel .e-toolbar-items {
    width: 100%;
    display: inline-table;
}

.grid-panel .e-scroll-nav {
    display: none;
}

.grid-panel .e-hscroll-content {
    width: 100%;
}

.grid-panel .e-toolbar-item {
    margin-right: 0 !important;
    height: auto;
}

.grid-panel > .e-tab > .e-tab-header > .e-toolbar-item {
    display: table-cell;
    width: 1% !important;
}

.grid-panel > .e-tab > .e-tab-header > .e-toolbar-item {
    padding: 0;
}

.grid-panel .e-tab-wrap,
.grid-panel .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    border: none;
}

.grid-panel .e-tab-wrap:hover,
.grid-panel .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
    background-color: white;
}

.e-content.e-lib.e-touch {
    height: auto !important;
}

div {
    box-sizing: border-box;
}

.box-header-button {
    color: white;
}

.box-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 0px;
}

/*Grid Paging tool*/

/*move up the page size chooser*/

.e-pagerdropdown {
    margin-top: -31px !important;
}

#GroupBox {
    display: inline-block;
    padding-left: 0;
    padding-bottom: 15px;
}

#GroupUsersBox {
    display: inline-block;
    padding-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Barlow', sans-serif;
}

.hedisGapDialogContent .e-dlg-content button {
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.hedisGapDialogContent .e-dlg-content button {
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.e-dlg-modal .e-footer-content button {
    min-width: 110px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.e-dlg-modal .e-footer-content button.e-filter-popup.e-control.e-btn.e-lib.e-flmenu-cancelbtn.e-flat {
    color: black;
}

.e-dlg-container .e-footer-content {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #eefbff;
}

.filterPanel-footer {
    margin-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    margin: 0px -10px 0px -10px;
    justify-content: center;
}

.vertical-flex {
    display: flex;
    flex-direction: column;
}

.horizontal-flex {
    display: flex;
    flex-direction: row;
}

.center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.e-control.e-btn.e-lib.e-primary {
    margin-top: 10px;
}

/*remove unexpected margin on prime [OK] button in advanced search result grid column chooser.*/

.e-footer-content .e-control.e-btn.e-lib.e-primary {
    margin-top: 0px;
}

.editJSA textarea {
    resize: none !important;
}

.button-container {
    padding: 10px;
    display: inline-block;
}

.input-row {
    display: flex;
    padding: 5px 10px;
}

/*Edit JSA Top Control Bar vertical alignment*/

.input-row .e-input-group.e-control-wrapper.e-ddl,
.input-row .e-input-group.e-control-wrapper.e-valid-input.e-datetime-wrapper {
    margin-bottom: 0px;
}

.question-container {
    border-bottom: solid 1px #bbbbbb;
    padding: 10px 10px 10px 0px;
    margin-left: 10px;
    background-color: #f7f7f7;
}

.question-container:last-child {
    border-bottom: none !important;
}

/*JSA Vertical Tab Component Section Title Width*/

.e-tab .e-tab-header.e-vertical {
    max-width: 300px;
    min-width: 300px;
}

.jsaTabComponent {
    background-color: #f7f7f7;
    border: solid 1px #bbbbbb;
    margin: 0px 3px 3px 3px;
    width: auto !important;
}

.jsaTabComponent::-webkit-scrollbar {
    display: none;
}

.jsaTabComponent .e-toolbar-item {
    margin-right: 0px !important;
}

.jsaTabComponent .e-tab-text {
    color: black !important;
}

.jsaTabComponent .e-item.e-active {
    border-top: none !important;
    border-left: solid 1px #bbbbbb;
    margin-left: -1px;
}

.jsaTabComponent .e-tab-wrap {
    border: solid 1px #bbbbbb !important;
    border-top: none !important;
    border-left: none !important;
}

.jsaTabComponent .e-tab-wrap:hover {
    background-color: rgba(22, 120, 173, 0.3) !important;
}

/*Remove right border of selected vertical jsa header*/

.jsaTabComponent .e-toolbar-item.e-active .e-tab-wrap {
    background-color: #f7f7f7 !important;
    border-right: none !important;
}

/*bold the selected tab item text*/

.jsaTabComponent .e-toolbar-item.e-active .e-tab-text {
    font-weight: 700 !important;
    color: #1675a9 !important;
}

/*alternates background color of vertical tab headers*/

.jsaTabComponent .e-toolbar-item:nth-child(odd) .e-tab-wrap {
    background-color: #d7d7d7;
}

.jsaTabComponent .e-toolbar-item:nth-child(even) .e-tab-wrap {
    background-color: #ededed;
}

/*increase vertical tab header item height*/

.jsaTabComponent .e-toolbar-item,
.jsaTabComponent .e-toolbar-item .e-tab-wrap,
.jsaTabComponent .e-toolbar-item .e-text-wrap {
    height: 40px !important;
}

.editJSA .editJSAInput .editJSABtnsContainer {
    margin: 5px;
    padding: 5px;
    text-align: center;
    color: white;
    position: sticky;
    bottom: 0;
    z-index: 999;
    background-color: rgb(255, 255, 255);
}

.editJSA .editJSALiveScore {
    color: black;
    border: 1px solid grey;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 5px 10px 10px 10px;
}

.editJSACancelBtn,
.editJSAResetBtn {
    background-color: #807f7f !important;
}

.radio-container {
    display: inline-block;
    margin-right: 20px;
}

.nonePointer {
    pointer-events: none;
}

.e-toast.green {
    background-color: #4d841d;
}

.e-toast.yellow {
    background-color: #e88321;
}

.e-toast.red {
    background-color: #ff0000;
}

#eligibilityRequestButton > .e-tbar-btn-text {
    color: white !important;
    font-weight: normal;
}

#authorizationRequestButton > .e-tbar-btn-text {
    color: white !important;
    font-weight: normal;
}

/** AHC UI Framework Specific CSS **/

.gridHeaderDiv.dark {
    background-color: rgb(0, 179, 227);
    color: #fff;
}

.gridHeaderDiv.dark.inactive {
    background-color: #808080;
    color: #fff;
}

.blue-border-bottom {
    border-bottom: solid 1px rgb(0, 192, 239);
}

.blue-border {
    border: solid 1px rgb(0, 192, 239) !important;
}

.section-margin {
    margin: 10px;
}

.section-padding {
    padding: 25px;
}

.box {
    border-radius: 0;
}

.row .box .box-solid .box-default .no-top-border {
    margin-top: 0px !important;
    margin-left: 10px !important;
}

.row .box .box-solid .box-default .no-top-border {
    margin-top: 0px !important;
    margin-left: 10px !important;
}

.box.section-margin {
    width: calc(100% - 20px);
}

.no-top-border {
    border-top: none !important;
}

.no-bottom-border {
    border-bottom: none !important;
}

.form-first-row {
    padding-top: 15px;
}

.ahc-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ahc-form .form-group {
    width: 100%;
}

.ahc-form .form-group.half {
    width: calc(50% - 5px);
}

.ahc-form .form-group label.required::after {
    color: red;
    content: ' *';
}

/*End AHC UI Framework Specific CSS*/

/** popup window styles **/

.nonClosableDialog .e-dlg-header-content .e-dlg-closeicon-btn {
    display: none;
}

.popup-window .e-dlg-content,
.popup-window #_dialog-content {
    padding: 0;
}

.e-popup-open.popup-window {
    overflow: hidden;
}

/* end popup window styles */

.jsaResetAnswerDialog .e-dlg-header-content {
    padding: 5px 10px !important;
}

.jsaResetAnswerDialog .e-dlg-content div {
    font-size: 14px !important;
}

.jsaResetAnswerDialog .e-footer-content {
    text-align: center !important;
}

.jsaResetAnswerDialog .e-footer-content button {
    background-color: #1675a9 !important;
    margin: 10px !important;
    color: white !important;
    transition: 0.3s;
}

.jsaResetAnswerDialog .e-footer-content button:hover {
    background-color: white !important;
    color: #1675a9 !important;
    border: solid 1px #1675a9 !important;
}

.failedGetJSAQuestion {
    height: 200px;
    text-align: center;
    font-size: 14px;
    line-height: 70px;
    padding-top: 40px;
}

/*
    JSA Questionnarie radio button, check box style
*/

.e-radio:checked + .e-info::after {
    /* csslint allow: adjoining-classes */
    background-color: #1675a9;
    border-color: #1675a9;
}

.e-radio:checked:focus + .e-info::after,
.e-radio:checked + .e-info:hover::after {
    /* csslint allow: adjoining-classes */
    background-color: #1675a9;
    border-color: #1675a9;
}

.e-radio:checked + .e-info::before {
    border-color: #1675a9;
}

.e-radio:checked:focus + .e-info::before,
.e-radio:checked + .e-info:hover::before {
    border-color: #1675a9;
}

.e-radio + .e-info:hover::before {
    border-color: #0b7dda;
}

.e-checkbox-wrapper.e-info .e-frame.e-check,
.e-checkbox-wrapper.e-info .e-checkbox:focus + .e-frame.e-check {
    /* csslint allow: adjoining-classes */
    background-color: #1675a9;
}

.e-checkbox-wrapper.e-info:hover .e-frame.e-check {
    /* csslint allow: adjoining-classes */
    background-color: #0b7dda;
}

.e-checkbox-wrapper.e-warning .e-frame.e-check,
.e-checkbox-wrapper.e-warning .e-checkbox:focus + .e-frame.e-check {
    /* csslint allow: adjoining-classes */
    background-color: #1675a9;
}

/*Global Dialog*/

.globalDialog .e-dlg-content {
    padding: 20px !important;
}

.globalDialog .e-dialog {
    position: relative;
}

.e-dialog .e-footer-content .e-control.e-btn.e-cancel {
    background-color: #808080 !important;
    color: white !important;
}

.e-dialog .e-footer-content .e-control.e-btn.e-cancel:hover {
    background-color: white !important;
    color: #808080 !important;
    border: solid 1px;
}

.globalDialog .e-control.e-btn.e-delete {
    background-color: #d64113;
    color: white;
}

.globalDialog .e-control.e-btn.e-delete:hover {
    background-color: white;
    color: #d64113;
    border: solid 1px;
}

.e-dialog .e-footer-content .e-control.e-btn.e-submit {
    background-color: #1675a9;
    color: white;
}

.e-dialog .e-footer-content .e-control.e-btn.e-submit:hover:not([disabled]) {
    background-color: white;
    color: #1675a9;
    border: solid 1px;
}

.globalDialog .e-control.e-btn.e-approve {
    background-color: #21ba45 !important;
    color: white !important;
}

.globalDialog .e-control.e-btn.e-approve:hover {
    background-color: white !important;
    color: #21ba45 !important;
    border: solid 1px;
}

.globalDialog .e-control.e-btn.e-deny {
    background-color: #db2828 !important;
    color: white !important;
}

.globalDialog .e-control.e-btn.e-deny:hover {
    background-color: white !important;
    color: #db2828 !important;
    border: solid 1px;
}

.globalDialog .e-control.e-btn.e-query {
    background-color: #f0ad4e !important;
    color: white !important;
}

.globalDialog .e-control.e-btn.e-query:hover:enabled {
    background-color: white !important;
    color: #f0ad4e !important;
    border: solid 1px;
}

.globalDialog .e-control.e-btn.e-pended,
.globalDialog .e-control.e-btn.e-2ndLevelReview,
.globalDialog .e-control.e-btn.e-mark-as-duplicate {
    background-color: #1675a9 !important;
    color: white !important;
    font-size: 12px;
    line-break: auto;
}

.globalDialog .e-control.e-btn.e-pended:hover:enabled,
.globalDialog .e-control.e-btn.e-2ndLevelReview:hover:enabled,
.globalDialog .e-control.e-btn.e-mark-as-duplicate:hover:enabled {
    background-color: white !important;
    color: #1675a9 !important;
    border: solid 1px;
}

.globalDialog .e-footer-content {
    padding: 5px 10px;
}

.globalDialog .e-dlg-container {
    width: 100vw;
    position: fixed !important;
}

.globalDialog .e-dlg-header-content {
    height: 50px;
    padding: 10px 15px;
}

.globalDialog .e-dlg-closeicon-btn.e-control.e-btn.e-lib.e-flat.e-icon-btn {
    height: 30px;
    padding: 6px 7px 7px 6px;
}

.globalDialog .e-dlg-closeicon-btn.e-control.e-btn.e-lib.e-flat.e-icon-btn:hover,
.e-dialog .e-btn.e-dlg-closeicon-btn:focus {
    background-color: red;
}

.globalDialog .e-dlg-closeicon-btn.e-control.e-btn.e-lib.e-flat.e-icon-btn:hover span {
    color: white;
}

.globalDialog .e-control.e-btn.e-lib.e-flat {
    height: 36px;
}

.globalDialog .button-yes:focus {
    color: white;
    background-color: #1675a9;
}

.globalDialog .e-btn:enabled:hover {
    background-color: rgba(226, 226, 226, 0.9844);
    border-color: transparent;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.87);
}

/*Global Dialog End*/

.e-dialog .e-dlg-header {
    color: white;
    width: calc(100% - 30px);
}

.e-dialog .e-dlg-header {
    color: white;
    width: calc(100% - 30px);
}

.removeMaxHeight {
    max-height: none !important;
}

i.disabled {
    opacity: 0.4;
    fill-opacity: 0.4;
}

.popover {
    max-width: 1200px;
}

.pointer {
    cursor: pointer;
}

.red-text {
    color: red;
    font-weight: 700;
}

.grid-panel > .e-control.e-tab.e-lib.e-keyboard .e-toolbar-item:not(.e-active) .e-tab-wrap:hover {
    background-color: #3071a9 !important;
}

.e-grid.e-gridhover
    tr[role='row']:not(.e-editedrow):hover
    div.e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    background-color: transparent;
}

.e-grid.e-gridhover
    tr[role='row']:not(.e-editedrow):hover
    td.e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    background-color: #eefbff;
}

.e-grid .e-groupdroparea.e-grouped {
    background-color: rgb(0, 179, 227);
}

.e-grid .e-groupheadercell {
    background-color: #3071a9;
}

.e-stackedheadercelldiv {
    color: black;
    font-weight: bold;
    font-size: 13px;
}

.jsaResetAnswerDialog .e-dlg-header-content .e-dlg-closeicon-btn {
    background-color: transparent !important;
}

/*P360 Member HCC Alerts Details Grid Template*/

.hcc-alerts-grid-details tr {
    vertical-align: top;
    border-bottom: #d8d8d8 solid 1px;
}

.hcc-alerts-grid-details tr:last-child {
    border-bottom: none;
}

.hcc-alerts-grid-details td {
    padding: 5px 0px;
}

.e-rowcell.e-templatecell.hcc-alerts-grid-cell-template,
.e-headercell.e-templatecell.hcc-alerts-grid-cell-template {
    padding: 10px 0px;
}

.HCCAlertGridDetail td.e-rowcell,
.HCCAlertGridDetail .e-gridheader .e-columnheader th {
    border: solid 1px #808080;
}

.HCCAlertGridDetail .e-gridheader .e-headercontent .e-columnheader th {
    border-bottom: solid 1px #808080;
}

/*Grid Group row padding removal*/

.e-grid-min-height .e-gridcontent .e-groupcaption {
    padding: 0;
}

.year-selector-div .e-input-group {
    padding: 0;
    margin: 0;
}

.worklistDiv .e-toolbar-item.e-search-wrapper.e-template {
    padding: 3.5px 0;
}

.e-item .e-toolbar-item.e-search-wrapper.e-template {
    padding: 5px 5px 6px;
}

.timeline::before {
    display: none;
}

.timeline {
    padding: 0;
    margin: 20px 0;
}

.last_item {
    visibility: hidden;
}

/*Edit JSA HCC Decision Content*/

.addedDiagnosisList {
    padding-top: 20px;
}

.addedDiagnosisList li {
    list-style-type: circle;
}

.vertical-timeline-element-icon .enrollmentIcon,
.vertical-timeline-element-icon .emergencyIcon,
.vertical-timeline-element-icon .psychIcon,
.vertical-timeline-element-icon .inpatientIcon,
.vertical-timeline-element-icon .outpatientIcon,
.vertical-timeline-element-icon .medicationIcon,
.vertical-timeline-element-icon .procedureIcon,
.vertical-timeline-element-icon .inboundIcon,
.vertical-timeline-element-icon .outboundIcon,
.vertical-timeline-element-icon .callIcon,
.vertical-timeline-element-icon .gymIcon,
.vertical-timeline-element-icon .transportationIcon,
.vertical-timeline-element-icon .dentalIcon,
.vertical-timeline-element-icon .visionIcon,
.vertical-timeline-element-icon .mealIcon,
.vertical-timeline-element-icon .teleDoctorIcon,
.vertical-timeline-element-icon .approveIcon,
.vertical-timeline-element-icon .denyIcon,
.vertical-timeline-element-icon .labIcon {
    width: 45px;
    height: 45px;
    margin-left: -22px;
    margin-top: -23px;
}

.vertical-timeline-element-icon .oopIcon {
    width: 45px;
    height: 45px;
    margin-left: -22px;
    margin-top: -15px;
}

.vertical-timeline-element-icon,
.blackCardIcon {
    width: 45px;
    margin-left: 7px;
    margin-top: 15px;
}

.vertical-timeline-element-date {
    font-size: 24px !important;
    top: -7px !important;
}

.vertical-timeline-element-detail {
    font-size: 15px;
    margin-bottom: 0;
    white-space: pre-line;
}

.vertical-timeline-element-tooltip {
    visibility: hidden;
    width: 600px;
    background-color: white;
    color: black;
    text-align: left;
    border: solid 1px;
    border-color: black;
    padding: 5px;
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 1;
    font-size: 15px;
    margin-bottom: 0;
    white-space: pre-line;
}

.vertical-timeline-element-content:hover .vertical-timeline-element-tooltip {
    visibility: visible;
}

.loader {
    display: inline-block;
    border: 2px solid #f3f3f3;
    /* Light grey */
    border-top: 2px solid #1675a9;
    /* Blue */
    border-radius: 50%;
    width: 23px;
    height: 23px;
    animation: spin 2s linear infinite;
    position: relative;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.e-gridcontent > .e-content > table > tbody > tr {
    border-top: none;
    border-bottom: 1px solid #e0e0e0;
}

.grid-panel .e-groupcaption {
    display: contents;
}

tbody > tr:first-child .group-template-cell {
    border: none;
}

.group-template-cell,
.group-template-cell:hover {
    display: table-cell;
    background-color: #fafafa !important;
}

#AdmitOrDischarge_control_container .e-radio-wrapper.e-wrapper {
    padding-right: 15px;
}

#CaseBox {
    display: inline-block;
    padding-left: 0;
    padding-bottom: 15px;
}

textarea[name='case-comments-textbox'] {
    resize: none !important;
    height: 130px !important;
}

.case-comment-save-button {
    float: right;
}

/*Create JSA Appointment Dialog Textarea*/

.jsaAppointmentDialog .e-input-group.e-control-wrapper.e-multi-line-input.e-valid-input textarea,
.jsaAppointmentDialog .e-input-group.e-control-wrapper.e-multi-line-input textarea {
    resize: none;
    height: 200px;
}

/*Schedule Appointment Button*/

.schuduleAppointmentButton.e-primary.e-control.e-btn.e-lib {
    margin-top: 0px;
}

/*JSA Schedule Appointment Dialog*/

.globalDialog .e-lib.e-dialog.scheduleAppointmentDialog.e-popup.e-control {
    max-height: none !important;
    max-width: none;
}

/*JSA Schedule Appointment Popup Dialog Header*/

.globalDialog .scheduleAppointmentDialog .e-header-icon-wrapper button {
    height: 2.5em !important;
}

/*Create New Event In Schedule Dialog Buttons*/

.e-control.e-btn.e-lib.e-primary.e-event-save.e-flat {
    margin-top: 10px;
    background: #1675a9;
    color: white;
}

.e-control.e-btn.e-lib.e-primary.e-event-save.e-flat:hover {
    background: white;
    color: #1675a9;
    border: 1px solid;
}

.e-control.e-btn.e-lib.e-event-cancel.e-flat {
    background: #808080;
    color: white;
}

.e-control.e-btn.e-lib.e-event-cancel.e-flat:hover {
    background: white;
    color: #808080;
    border: 1px solid;
}

/*END*/

/*Hide ALl-Day Row*/

.e-schedule .e-date-header-wrap .e-schedule-table thead {
    display: none;
}

.switch-button {
    height: 30px;
    margin-top: -6px;
}

.switch-icon::before {
    content: '\e308';
    display: inline;
    font-size: 20px;
    position: relative;
    left: -4px;
}

.hidden-element {
    visibility: hidden;
}

.visible-element {
    visibility: visible;
}

.condition-delete-button {
    float: right;
    height: 32px;
}

textarea[name='custom-query'] {
    height: 67px !important;
    resize: none !important;
}

.error-message {
    color: red;
    display: inline-block;
    margin-top: 3px;
}

.e-send-icon:before {
    content: '\e816';
}

textarea[name='reject-reason-textbox'] {
    resize: none !important;
}

/*pdf viewer styles start*/

.react-pdf__Document:hover .page-controls {
    opacity: 1;
}

.page-controls {
    width: fit-content;
    font-size: 1.5rem;
    text-align: center;
    position: absolute;
    bottom: 5%;
    left: 50%;
    background: white;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
}

.page-movement {
    margin: 0 10px;
    cursor: pointer;
}

.page-movement:hover {
    background-color: #e6e6e6;
}

.disabled-icon {
    cursor: auto;
    background-color: white;
}

.page-movement.disabled-icon:hover {
    background-color: white;
}

/*pdf viewer styles end*/

/* Enrollment Productivity styles start */

.enrollment-chart-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    justify-content: space-between;
}

.enrollment-chart-container {
    width: 100%;
    height: 400px;
    margin: 0.4% 0;
}

.chart-title {
    width: 100%;
    text-align: center;
}

.piechart-centertitle {
    visibility: hidden;
    position: absolute;
    width: 10%;
    font-weight: 600;
    font-size: 13px;
}

.enroll-docslist-row {
    padding: 5px;
    border-bottom: 1px solid #baebf9;
    height: 35px;
    color: #03659a;
    font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', 'sans-serif', '-apple-system', 'BlinkMacSystemFont';
    font-size: 14px;
}

.enroll-docslist-row span {
    padding: 5px;
    float: left;
    height: 35px;
}

/* Enrollment Productivity styles end */

.p360SearchContentDiv svg {
    display: inline;
    margin: 0 3px 0 -3px;
}

.report-style-class {
    height: 100vh;
}

.e-schedule-dialog .e-dlg-header-content {
    padding: 15px 20px;
}

.header-content {
    color: #03659a;
    background-color: #eefbff;
}

.header-content-text > div {
    border-top: solid 2px rgb(186, 235, 249);
    padding: 3px;
}

.e-label-select {
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.54);
}

.float-input-field {
    display: flex;
    color: darkgrey;
    margin-bottom: 5px;
}

.text-value {
    margin-bottom: 10px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item:not([title='Collapse']) {
    vertical-align: middle;
}

.searchRow > .searchTextBox .e-ddl .e-ddl-icon {
    display: none;
}

.autocomplete-overlay-spinner {
    margin-top: -65px;
    position: relative;
}

.autocomplete-overlay-spinner .e-spinner-inner {
    left: unset;
    right: -5px;
}

.census-spinner .e-spinner-pane .e-spinner-inner {
    border-width: 8px !important;
    width: 100px !important;
    height: 100px !important;
}

.spinnerWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
    pointer-events: none;
}

.e-cell {
    text-align: left !important;
}

.e-file-menu > .e-menu-parent > .e-disabled {
    display: none !important;
}

.e-content.e-dropdownbase {
    height: 100%;
    background-color: white;
}

.auth-view-navigation-actions.e-dropdown-btn {
    color: white;
    background-color: #1675a9;
}

.assessmentGridSelectedRow {
    background-color: #60bed7;
}

.closableBoxWrapper {
    display: flex;
    padding: 5px 10px 5px 15px;
    background-color: #eefbff;
    border: solid 1px #60bed7;
}

.closableBoxBody {
    border-left: solid 1px #aeb6b8;
    border-right: solid 1px #aeb6b8;
    border-bottom: solid 1px #aeb6b8;
}

.e-dialog .e-dlg-header-content + .e-dlg-content {
    padding-top: 10px;
}

.no-shadow {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.e-disabled.mui-disabled-input {
    border: solid black 1px !important;
    border-bottom: none !important;
    border-radius: 5px !important;
}

#PivotView_data_PivotFieldList_EditorTreeView
    > div.e-footer-content
    > button.e-control.e-btn.e-lib.e-cancel-btn.e-flat {
    color: #e3165b !important;
    margin-bottom: 8% !important;
}

.ReadOnlyTextBoxClaimPayment {
    font-weight: bold;
}

.e-pv-organize-window > .e-footer-content > .e-control:first-child {
    display: none;
}

.e-pv-organize-anchor-node {
    width: 280px !important;
    height: 280px !important;
    margin: 15px !important;
}

.e-pv-insert-left-button,
.e-pv-insert-right-button,
.e-pv-copy-button {
    display: none !important;
}

.e-toolbar-right > .e-toolbar-item:first-child > .e-tbar-btn > .e-pv-icon::before {
    font-size: 20px;
}

.provider-privileging-table {
    border-collapse: collapse;
    width: 100%;
}

.provider-privileging-table th,
.provider-privileging-table td,
.provider-privileging-table tr {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

.provider-privileging-table th {
    background-color: #f2f2f2;
}

.member-address-template p {
    margin: 0;
}

.e-tooltip-wrap .e-tip-content {
    font-size: 14px;
    text-align: justify;
}
