$blue: #00b3e3;
$light-blue: #8ed2e5;
$dark-blue: #1d86b7;
$red: #ff5050;

@mixin corner-style() {
    border-radius: 5px !important;
}

.formWrapper {
    margin: 2rem 3rem;
}

.row {
    display: flex;
}

.col {
    width: 100%;
}

.label {
    margin-bottom: 3px;
    font-weight: 500;
    color: #000;
}

.input {
    margin-bottom: 4px !important;
    @include corner-style();
    input,
    textarea {
        padding-left: 5px !important;
    }
}

.customBorder {
    @include corner-style();
}

.errorInput {
    border-color: $red !important;
}

.text {
    height: 14px;
    margin: 0;
    color: gray;
    font-size: 10.5px;
}

.errorText {
    color: $red;
}

.textareaGroup {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.textarea {
    flex: 1;
}

.uploaderWrapper {
    border: 1px dashed #aaaaaa;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    margin-bottom: 4px;
    font-size: 13px;
}

.uploader {
    display: none;
}

.browseBtn {
    color: $dark-blue;
    font-weight: 600;
    &:hover {
        cursor: pointer;
    }
}

.btnGroup {
    text-align: center;
    button {
        @include corner-style();
        font-size: 12px;
        width: 15%;
        margin: 0px 1rem;
        padding: 6px 0;
        outline: none !important;
        box-shadow: none !important;
        &.btnSecondary {
            background-color: #6c757d !important;
        }
    }
}

.tableWrapper {
    margin-top: 1.8rem;
    & > div {
        &:last-child {
            & > div {
                border: 1px solid $blue;
            }
        }
    }
}

.table {
    margin: 0;
    th,
    td {
        padding: 15px;
    }
    td {
        font-weight: 500;
    }
    a {
        text-decoration: none;
        color: $dark-blue;
    }
}

.historyGridWrapper {
    border-color: $light-blue;
    & > div:first-child {
        border-bottom: none;
    }

    .historyGrid {
        border: none;
    }
}
