#video-container {
    position: fixed;
    width: 100%;
    min-width: 100vh;
    height: 100%;
    top: 0;
    left: 0;
    text-align: right;
    pointer-events: none;
    z-index: 10001 !important;
}

#virtual-visit-pcp {
    border: 1px solid rgb(0, 179, 227);
    width: 40%;
    height: 85%;
    pointer-events: all;
    background: #fff;
    cursor: move;
    left: calc(100vw - 550px);
    position: fixed;
    display: flex;
    flex-direction: column;
    min-width: 300px !important;
    max-height: 100vh;
    max-width: 500px;
}

#virtual-visit-pcp.maximize {
    top: 0 !important;
    right: 0;
    bottom: 0;
    left: 0 !important;
    height: auto !important;
    width: auto !important;
    max-width: unset !important;
    max-height: unset !important;
}

#virtual-visit-pcp.maximize div#resizer {
    display: none;
}

#virtual-visit-pcp.just-audio {
    width: 300px;
    height: 300px;
    bottom: 300px;
}

#resizer {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: se-resize;
    background: #03a7d2;
}

.expand-icon {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    color: #fff;
    position: absolute;
    left: 7px;
    top: 5px;
}

.maximize .participants-container {
    height: calc(100vh - 50px);
    width: 74%;
    margin: 0 auto;
    float: left;
}

.just-audio .participants-container {
    height: 40px;
}

.virtual-visit-header {
    display: flex;
    padding: 10px 10px 10px 15px;
    background-color: #03a7d2;
    border-bottom: solid 1px #60bed7;
    max-height: 50px;
    color: #fff;
    align-items: center;
}

.virtual-visit-body {
    text-align: left;
    padding: 5px;
    color: black;
    position: relative;
    flex-grow: 1;
    display: flex;
    overflow-y: auto;
}

.virtual-visit-body:hover {
    cursor: default !important;
}

.virtual-visit-body.black {
    background: #0a0a0c;
    color: #fff;
}

.virtual-visit-body button#start-virtual-conference {
    width: 70%;
}

.room {
    width: 100%;
}

.room div {
    word-wrap: break-word;
}

.room div:hover {
    cursor: default;
}

.virtual-visit-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.virtual-visit-buttons svg {
    margin: 0 10px;
    cursor: pointer;
}

.virtual-visit-buttons svg[data-icon='window-minimize'] {
    margin-top: -7px;
    cursor: pointer;
}

.join-videocall-btn {
    background-color: rgb(0, 179, 227);
    width: 220px;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin: 10px auto 40px;
}

.just-audio .join-videocall-btn {
    max-width: 200px;
}

.red-point {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: red;
    margin-top: 5px;
}

.toolbar {
    background-color: #edfafd;
}

#start-virtual-conference .e-btn-content {
    text-transform: none;
}

#minimize-pcp-content {
    position: fixed;
    bottom: 5%;
    right: 3%;
}

.call-minimize-btn,
.call-minimize-btn:hover,
.call-minimize-btn:focus {
    background-color: #0b86a5 !important;
    width: 320px;
    height: 70px;
    border-radius: 40px;
    color: #fff !important;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    position: relative;
}

.call-minimize-btn div span {
    margin-left: 5px;
}

.hide-call {
    visibility: hidden;
}

.minimize-pcp-fade {
    opacity: 0.1;
}

.minimize-pcp-fade-enter {
    opacity: 0.2;
    transform: translateY(150px);
    transition: all 0s;
}

.minimize-pcp-fade-enter-active {
    opacity: 0.75;
    transform: translateY(0);
    transition: all 700ms;
}

.minimize-pcp-fade-enter-done {
    opacity: 1;
}

.virtual-visit-body-textchat {
    background-color: white;
    color: black;
    padding: 15px;
}