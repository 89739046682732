.cancel-button {
    background-color: #808080;
    color: white;
    margin-right: 1rem;
}

.notes-container {
    margin: 15px 15px 0px 15px;
    text-align: left;
}

.buttons-container {
    margin: 0 15px 0 15px;
    padding: 15px 0 15px 0;
    text-align: left;
}
