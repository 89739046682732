.viewPatient360 {
    color: darkcyan;
    border-radius: 8px;
    margin-right: 10px;
}

.viewPatientJSA {
    color: crimson;
    border-radius: 8px;
    margin-right: 10px;
}

.scheduleVirtualVisit {
    border-radius: 8px;
    margin-right: 10px;
}

.scheduleVirtualVisit,
.scheduleVirtualVisit:active,
.scheduleVirtualVisit:focus {
    color: #fff;
    background-color: darkcyan;
}

.scheduleVirtualVisit:hover {
    color: #fff;
    background-color: #0b7b7b;
}

.scheduleVirtualVisitBtn,
.schedulingAssistantBtn {
    border-radius: 8px;
    margin-right: 10px;
    height: 30px;
}

.scheduleVirtualVisitBtn,
.scheduleVirtualVisitBtn:active,
.scheduleVirtualVisitBtn:focus,
.schedulingAssistantBtn,
.schedulingAssistantBtn:active,
.schedulingAssistantBtn:focus {
    color: #fff;
    background-color: darkcyan;
}

.scheduleVirtualVisitBtn:hover,
.schedulingAssistantBtn:hover,
.editApptBtn:hover,
.rescheduleBtn:hover {
    color: #fff;
    background-color: #0b7b7b;
}

.Completed.and.Cancelled.Virtual.Visits .rescheduleBtn {
    display: none;
}

.rescheduleBtn,
.editApptBtn,
.dialogBtn {
    background-color: darkcyan;
    color: #fff;
    border-radius: 8px;
    margin-right: 10px;
}

.virtualAppointmentWrapper {
    font-size: 15px;
    font-weight: 700;
    line-height: 30px;
    background-color: #00b3e3;
}

.virtualAppointmentWrapper .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 15px;
}

.virtualAppointmentWrapper .header .title {
    color: white;
}

.virtualAppointmentWrapper .header .date {
    margin-left: 15px;
    color: white;
}

.virtualPCPButton.e-danger {
    float: right;
    background-color: transparent;
    box-shadow: none;
    border-radius: 50%;
    opacity: 1;
}

.virtualPCPButton.e-danger:hover {
    background-color: red;
    opacity: 1;
    transition: all 280ms;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.messageCenterTabCententWrapper {
    border: 1px solid #00b3e3;
    min-height: 700px;
    background: white;
    padding: 5px;
}

.messageCenterTabCententWrapper .seachboxSection {
    height: 50px;
    width: 50%;
    padding: 10px 0px 10px 0px;
}

.messageCenterTabCententWrapper .toolbar {
    height: 50px;
    padding: 10px 0px 10px 0px;
}

.messageCenterTabCententWrapper .toolbar i,
.messageCenterTabCententWrapper .toolbar span {
    color: #00b3e3;
}

.messageCenterTabCententWrapper .toolbar span {
    margin-left: 5px;
}

.messageCenterTabCententWrapper.composeMessage {
    padding: 0;
}

.displayMessage {
    margin: 20px;
    font-size: 14px;
}

.displayMessage ul {
    padding: unset;
}

.displayMessage ul li label {
    font-weight: 700;
}

.displayMessage ul li span {
    padding-left: 5px;
}

.messageSeparator {
    opacity: 0.3;
}

.composeMessage .metaData div {
    display: flex;
    margin: -1px 10px 0;
    height: 33px;
    line-height: 33px;
    border: 1px solid #dadada;
    font-size: 14px;
}

.composeMessage .metaData div:first-of-type {
    background-color: #ebebeb;
}

.composeMessage .metaData span {
    padding-left: 10px;
    padding-right: 10px;
    min-width: 70px;
    height: 28px;
    text-align: right;
    color: #777;
}

.composeMessage .metaData input {
    flex-grow: 20;
    margin-left: 15px;
    padding: 0;
    height: 33px;
    border: none;
    font-weight: bold;
    background: none;
}

.composeMessage .metaData label {
    flex-grow: 1;
    color: red;
}

.composeMessage .messageContent {
    display: flex;
    margin: 0 10px;
}

.composeMessage .messageContent textarea {
    padding: 15px 15px;
    width: 100%;
    height: 350px;
    resize: none;
    border: 1px solid #dadada;
    color: #777;
}

.composeMessage .buttonGroup {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    margin-bottom: 10px;
    padding: 14px 0 8px;
    width: 100%;
    background-color: #edfafd;
}

.composeMessage .buttonGroup button {
    margin-left: 10px;
    margin-right: 10px;
    height: 26px;
    font-weight: normal;
    text-transform: none;
    box-shadow: none;
}

.composeMessage .buttonGroup button.e-submit,
.composeMessage .buttonGroup button.e-submit .e-path-circle {
    padding: 0 25px;
    background-color: #0099a8;
    color: white;
    stroke: #fff;
    border-radius: 4px;
}

.composeMessage .buttonGroup button.e-cancel,
.composeMessage .buttonGroup button.e-flat {
    padding-top: 3px;
    padding-right: 32px;
    color: #1675a9;
    background-color: transparent;
    border-radius: 4px;
}

.composeMessage .buttonGroup button.e-cancel svg {
    margin-right: 8px;
    font-size: 12px;
}

.composeMessage .buttonGroup button.e-flat i {
    margin-right: 8px;
    font-size: 19px;
    vertical-align: -3px;
}

.composeMessage .buttonGroup button.e-submit:hover {
    background-color: #fff;
    color: #1675a9;
    border: 1px solid #1675a9;
}

.composeMessage .buttonGroup button.e-submit:hover .e-path-circle {
    stroke: #1675a9;
}

.composeMessage .buttonGroup button.e-submit:disabled,
.composeMessage .buttonGroup button.e-submit:disabled:hover {
    background-color: #65d7e2;
    color: white;
    border: none;
    cursor: pointer;
}

.attendees-list span.e-headertext {
    margin-left: 0;
}

#globalDialog button[disabled] {
    pointer-events: none;
    opacity: 0.65;
}

div.metaData div i {
    margin: 0 15px;
    width: 14px;
    color: #777;
    vertical-align: baseline;
    line-height: inherit;
}

.appointmentDialogContent .message-body {
    min-height: 100px;
    max-height: 100px;
}
