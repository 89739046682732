.controls-management {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 22px;
}

.maximize .controls-management {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 75%;
    font-size: 22px;
}

.just-audio .controls-management {
    margin-top: 15px;
}

.white-btn,
.red-btn {
    width: 65px;
    height: 65px;
    border-radius: 35px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.white-btn {
    background: #fff;
    color: #2b2c33;
    font-size: 24px;
}

.white-btn:hover {
    background: #e8e6e6;
    cursor: pointer !important;
}

.blur-btn {
    width: 65px;
    height: 65px;
    border-radius: 35px;
    margin: 5px 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #2b2c33;
    font-size: 24px;

    .blurIcon {
        position: absolute;
        margin-bottom: 20px;
    }
    .blurSwitch {
        position: absolute;
        margin-top: 25px;
    }
}

.red-btn {
    background: #f44336;
}

.red-btn:hover {
    background: #d02f23;
    cursor: pointer !important;
}

.room .loading-spinner {
    position: absolute;
    top: 48%;
    left: -webkit-calc(50% - 15px);
    left: -moz-calc(50% - 15px);
    left: calc(50% - 15px);
}

.participants-container {
    position: relative;
    min-height: 300px;
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

#virtual-visit-pcp.maximize .virtual-visit-body .room .participants-container .local-participant {
    height: 25%;
}

.local-participant {
    position: absolute;
    bottom: 10px;
    right: 5px;
    display: flex;
}

.remote-participants {
    width: 100%;
    max-width: 620px;
}

.remote-participants .participant {
    width: 100%;
    min-height: 100%;
    height: 100%;
}

.remote-participants .participant video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.maximize .remote-participants {
    width: 100%;
    height: 100%;
    max-width: 1400px;
}

.maximize .videocall-details {
    float: right;
    width: 25vw;
}

.maximize .remote-participants .participant,
.maximize .remote-participants .participant video {
    text-align: center;
    height: 100%;
}

.maximize .remote-participants .participant video {
    width: 100%;
}

.local-participant .participant {
    margin-left: 5px;
    border: 2px solid #fff;
    height: 102px;
    width: 102px;
    overflow: hidden;
}

.just-audio .local-participant .participant {
    border: none;
    height: 0;
}

.maximize .local-participant .participant {
    height: 100%;
    width: 100%;
}

.local-participant .participant video {
    height: 115px;
    width: 115px;
    object-fit: cover;
}

.maximize .local-participant .participant video {
    height: 100%;
    width: 100%;
}

.participants-list {
    padding: 20px 10px 10px 10px;
    bottom: 0;
    margin: 0 auto;
}

.permissions-settings-info {
    margin-top: 15px;
    margin-left: 10px;
}

.participants-title {
    color: #fff;
    font-size: 27px;
    padding-bottom: 10px;
    font-weight: bolder;
}

.participant-item {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    align-content: center;
    align-items: center;
    height: 24px;
    font-size: 18px;
}

.participant-buttons {
    display: flex;
}

.participant-btn {
    cursor: pointer;
    margin: 5px;
    width: 22px;
    text-align: center;
}

.alert-waiting {
    margin-top: 30%;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.just-audio .alert-waiting {
    margin-top: 0;
    line-height: 40px;
    font-size: 18px;
}

.maximize .alert-waiting {
    margin-top: 40vh;
    text-align: center;
}

.maximize .participants-list {
    margin: 40px;
    padding: 0;
}

.hide-video {
    display: none;
}
