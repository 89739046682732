.formula_form_popup_overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.089);
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .formula_form_popup_content {
        height: 670px;
        width: 800px;
        background-color: white;
    }

    .custom-dialog-header {
        height: 50px;
        padding: 15px;
        display: block;
        font-size: 18px;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-user-select: none;
        user-select: none;
        white-space: nowrap;
        color: white;
        background-color: #00b3e3;
    }

    .custom-dialog-content {
        padding: 20px;
    }
}

