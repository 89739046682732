.box {
    display: inline-block;
}

.content {
    padding-right: 0;
}

.description {
    line-height: 18px;
    word-wrap: break-word;
}

.header {
    margin-top: 0;
}

.radio-choice {
    display: inline-block;
    padding-right: 5px;
}

.ava-form-label {
    font-size: 14px;
    color: rgb(148, 148, 148);
    padding-bottom: 6px;
}

#GroupBox {
    padding-left: 0;
    padding-bottom: 15px;
}

#GroupDescriptionText {
    width: 100px;
}

#GroupUsersBox {
    padding-left: 0;
}

#HiddenInput {
    visibility: hidden;
    display: none;
}

.e-grid .e-dialog.e-checkboxfilter {
    min-height: 200px;
    height: 275px;
}

.filter-buttons-alignment div.filters-filter-controls {
    align-self: flex-end;
}

.provider-search-dialog {
    min-height: 400px;
    height: 600px;
    overflow: auto;
}

@media (max-width: 768px) {
    .filter-buttons-alignment div.filters-filter-controls {
        align-self: center;
    }

    .provider-search-dialog {
        min-height: 200px;
        height: 275px;
        overflow: auto;
    }
}

.main-menu #main-treeview .e-list-item.e-node-focus > .e-fullrow {
    background-color: transparent;
    border-color: transparent;
}
