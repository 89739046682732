.page1CMSEligibilityCheck {
    .main-section {
        padding: 0px !important;
        min-width: auto !important;
        .cmsEligibilityCheck{
            width:100% !important;
        }
    }
}
.addressHeading {
    color: #0376a8;
    font-weight: 600;
}
.e-currentAddress{
    background-color: tomato;
    color: white;
    cursor:pointer;
}
.verifiedMemberCss {
    float: right;
    width: 20px;
    height: 20px;
}
.verified {
    color: #198754;
}
.notVerified {
    color: #dc3545;
}
.closeIcon {
    position: absolute;
    right: 2px;
    top: 10px;
    z-index: 9999;
    background-color: #00b3e3;
    color: #fff;
    display: inline-block;
    width: 50px;
    height: 38px;
    padding-top: 5px;
    padding-left: 13px;
}

.e-dlg-header-content:has(.closeIcon) {
    background-color: #00b3e3 !important;
}

.nameTextMainWrap{
    .nameTextWrap {
        width: 30%;
        display: inline-block;
    }
}
.customSearchWrap {
    border: 1px solid grey;
    border-radius: 5px;

    .e-input-group {
        border: none !important;
        margin-bottom: 0px !important;
    }

    .salesSearchBtn {
        /*border-left: 1px solid grey !important;*/
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border: none;
        padding: 7px 15px;
        font-size: medium;
        color: #fff;
        font-size: 1.4em;
    }
}
.modalHeader {
    margin-top: 5px;
    font-size: 18px;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    margin-bottom: 0px !important;
}
.defaultStatus {
    background-color: #d6f4fc;
    color: #0376a8;
}
.defaultBorder {
    border: 1px solid #56c8e8;
}
.completedStatus {
    border-bottom: 1px solid rgb(64, 201, 25);
    background-color: rgb(64, 201, 25);
    color: #fff;
}
.completedStatusBorder {
    border:1px solid rgb(64, 201, 25);
}
.rejectedStatus {
    border-bottom: 1px solid rgb(191, 25, 50);
    background-color: rgb(191, 25, 50);
    color: #fff;
}
.rejectedStatusBorder {
    border: 1px solid rgb(191, 25, 50);
}
.inProgressStatus {
    color: #fff;
    border-bottom: 1px solid rgb(226, 184, 47);
    background-color: rgb(226, 184, 47);
}
.inProgressStatusBorder {
    border: 1px solid rgb(226, 184, 47);
}
.waitingStatus {
    color: #fff;
    background-color: rgb(255, 141, 97);
}
.waitingStatusBorder {
    border: 1px solid rgb(255, 141, 97);
    border-bottom: 1px solid rgb(255, 141, 97);
}
.invalidStatus {
    color: #fff;
    background-color: rgb( 255, 125, 77);
}
.invalidStatusBorder {
    border: 1px solid rgb( 255, 125, 77);
    border-bottom: 1px solid rgb(255, 125, 77);
}
.voidStatus {
    color:#fff;
    background-color: #800000;
}
.voidStatusBorder {
    border: 1px solid #800000;
    border-bottom: 1px solid #800000;
}

.page1 {
    .e-toolbar {
        display: none;
    }

    .e-headercell, .e-detailheadercell {
        background-color: #efefef !important;
    }
}

.hideElement {
    display: none;
}
.e-dlg-overlay{
    pointer-events:none;
}
.enrollmentDetailsPage {
    padding: 10px;

    .enrollmentAssignmentContainer {
        /*border: 1px solid #56c8e8;*/
        background-color: #fff;

        .enrollmentAssignmentHeader {
            /*background-color: #d6f4fc;*/
            /*color: #0376a8;*/
            font-size: 16px;
            padding: 10px 15px;
            font-weight: 500;
            /*border-bottom: 1px solid #56c8e8;*/
        }

        .enrollmentAssignmentContent {
            padding: 15px;

            .btnAlignCss {
                width: 100%;
                border: none;
                margin-top: 24px;
                padding: 9px;
                border-radius: 3px;
            }
        }
    }

    .enrollmentDocumentContainer {
        /*border: 1px solid lightgray;*/
        background-color: #fff;

        .enrollmentDocumentHeader {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            padding: 10px 15px;
            font-weight: 500;
            border-bottom: 1px solid lightgray;

            .minimizeIcon {
                float: right;
                cursor: pointer;
            }
        }

        .enrollmentDocumentContent {
            transform-origin: top;
            transition: all 0.1s ease-out;
            transform: scaleY(20px);
            padding: 15px;

            .fileUploadContainer {
                border: 1px dashed #80b1cb;
                justify-content: center;
                padding: 1rem 0;
                margin-bottom: 4px;
                font-size: 13px;

                .uploadIcon {
                    font-size: 70px;
                    color: #3c8dbc;
                    fill: #3c8dbc
                }

                .browseBtn {
                    color: #1d86b7;
                    font-weight: 600;
                    cursor: pointer;
                }

                .e-upload {
                    border: none;
                    text-align: center;

                    .e-btn {
                        box-shadow: none;
                        color: #1d86b7;
                        font-weight: 600;
                        cursor: pointer;
                        border: none;
                        background-color: transparent;
                    }

                    .e-file-drop {
                        display: block;
                    }

                    .e-upload-files {
                        display: none;
                    }
                }

                .acceptedFormatLbl {
                    margin-top: 10px;
                    color: lightgray;
                }
            }

            .uploadDocBtn {
                float: right;
                margin-top: 5px;
            }

            .e-toolbar {
                display: none;
            }

            .e-headercell, .e-detailheadercell {
                background-color: #efefef !important;
            }

            .e-gridheader {
                padding-right: 0px !important;
            }

            .dashedHr {
                border: 2px dashed #000;
                border-style: none none dotted;
                color: #fff;
                background-color: #fff;
            }

            .notesHeading {
                color: #0376a8;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .textareaCss {
                width: 100%;
                height: 120px;
                border: 1px solid #949494;
                padding: 8px;
            }

            .notesContent {
                display: inline-block;
                width: 100%;
            }

            .readMoreBtn {
                background-color: transparent;
                border: none;
                color: #1a83b0;
                outline: none;
                font-weight: 600;
            }

            .showAllContents {
                height: auto;
                white-space: normal;
            }
        }
    }

    .enrollmentDetailsStepperContainer {
        background-color: #fff;

        .enrollmentDetailsHeader {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            padding: 10px 15px;
            font-weight: 500;
            border-bottom: 1px solid lightgray;
        }

        .enrollmentStepperFormContent {
            padding: 15px;

            .e-disabled {
                background-color: #f4f4f4 !important;
                border-bottom-color: #949494 !important;

                .e-control {
                    border-radius: 4px !important;
                }
            }

            .actionBtnGroup {
                text-align: center;

                .e-ddl {
                    padding: 0px 5px !important;
                }

                .customBtn {
                    width: 190px;
                    padding: 7px 0px;
                }

                .submitBtn {
                    background-color: #05ad0d;
                    color: #fff;
                }

                .e-input-group-icon {
                    color: rgba(0,0,0,.54) !important;
                }
            }

            .stepperTab {
                width: 100%;

                .stepperBtn {
                    background-color: #0f99a8;
                    border: none;
                    border-right: 0.5px solid #d6f4fc;
                    color: #fff;
                    text-align: center;
                    width: 100%;
                    padding: 8px 0px;
                    cursor: pointer;
                    font-weight: 500;
                    transition: 0.5s;
                    transition-timing-function: linear;
                }

                .active {
                    transform: skewY(0deg);
                    background-color: #fff !important;
                    color: #000 !important;
                }
            }

            .detailsContainer {
                padding: 15px;
                transition: 0.5s;
                transition-timing-function: linear;

                .heading {
                    color: #0376a8;
                    font-weight: 600;
                }

                .bubble {
                    --r: 25px; /* the radius */
                    --t: 15px; /* the size of the tail */
                    max-width: 400px;
                    padding: calc(2*var(--r)/4);
                    -webkit-mask: radial-gradient(var(--t) at var(--_d) 0,#0000 98%,#000 102%) var(--_d) 100%/calc(100% - var(--r)) var(--t) no-repeat, conic-gradient(at var(--r) var(--r),#000 75%,#0000 0) calc(var(--r)/-2) calc(var(--r)/-2) padding-box, radial-gradient(50% 50%,#000 98%,#0000 101%) 0 0/var(--r) var(--r) space padding-box;
                    background: #00b3e3;
                    color: #fff;
                }

                .left {
                    --_d: 0%;
                    border-left: var(--t) solid #0000;
                    margin-right: var(--t);
                    place-self: start;
                    position: ABSOLUTE;
                    top: -121px;
                    left: 183px;
                }

                .formContent {
                    padding: 10px 0px 0px;
                    transition: 0.5s;
                    transition-timing-function: linear;

                    .e-accordion {
                        border: 1px solid #d6f4fc;

                        .e-acrdn-item {
                            border: none !important;

                            .e-acrdn-header {
                                background-color: #fd7e14;

                                .e-acrdn-header-content {
                                    color: #000;
                                    font-size: 16px;
                                }

                                .e-toggle-icon {
                                    color: #000;
                                }
                            }
                        }

                        .e-acrdn-header:hover:focus {
                            background-color: #fd7e14;

                            .e-acrdn-header-content {
                                color: #000;
                                font-size: 16px;
                            }

                            .e-toggle-icon {
                                color: #000;
                            }
                        }

                        .pcpContainer {
                            padding: 10px;
                            background-color: #f7f7f7;
                            color: #000;

                            .content {
                                padding: 0px;
                                margin-bottom: 10px;
                                font-size: 14px;

                                label:first-child {
                                    width: 120px;
                                }
                            }
                        }
                    }

                    .invalidData {
                        .e-input-group {
                            border-color: #dc3545 !important
                        }
                    }

                    .autoCompleteContainer {
                        .MuiInputBase-root {
                            padding-top: 0px !important;
                            padding-bottom: 0px !important;

                            .MuiAutocomplete-input {
                                font-size: 13px !important;
                            }

                            .MuiAutocomplete-endAdornment {
                                right: 6px !important;

                                .MuiAutocomplete-popupIndicator {
                                    .MuiSvgIcon-root {
                                        font-size: 27px !important;
                                        fill: #777 !important;
                                    }
                                }

                                .MuiAutocomplete-clearIndicator {
                                    background-color: transparent !important;

                                    .MuiSvgIcon-root {
                                        font-size: 17px !important;
                                        fill: #777 !important;
                                    }
                                }
                            }
                        }

                        .MuiInputBase-root:has(.Mui-disabled) {
                            background-color: #f4f4f4 !important;
                        }
                    }

                    .requiredAsterisk, .requiredField {
                        color: red;
                    }

                    .e-date-wrapper, .e-ddl {
                        padding: 0px 5px !important;
                    }

                    .languageContainer {
                        e-label::first-letter {
                            text-transform: capitalize;
                        }
                    }

                    .verifyBtn {
                        border: none;
                        padding: 6px 60px;
                        border-radius: 3px;
                    }

                    .e-radio:checked + label::after {
                        background-color: #0376a8;
                        color: #0376a8;
                    }

                    .e-radio:checked + label::before {
                        background-color: #fff;
                        border-color: #0376a8;
                    }

                    .e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
                        background-color: #0376a8;
                        border-color: rgba(0,0,0,0);
                        color: #fff;
                    }
                }

                .dashedHr {
                    border: 2px dashed #000;
                    border-style: none none dotted;
                    color: #fff;
                    background-color: #fff;
                }

                .activeChronicConditionOptions {
                    background-color: #efeded;
                }

                .chronicConditionOptions {
                    padding: 10px;
                }
            }
        }
    }
}

    .downloadFileLink {
        text-decoration: underline !important;
        color: blue !important;
        cursor: pointer !important;
    }

    .downloadFileLink:hover {
        text-decoration: underline !important;
    }

    .successStatus {
        color: forestgreen;
    }

    .pendingStatus {
        color: orangered;
    }

    .cancelStatus {
        color: red;
    }

    .e-toast-container .e-toast.e-toast-UM-success {
        background-color: #daf0b7;
        border: 1px solid #78bd42;
        border-left: 6px solid #8db600;
    }

    .e-toast-container .e-toast.e-toast-UM-success .e-toast-UM-success__content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .e-toast-container .e-toast.e-toast-UM-error {
        background-color: #ffe0d7;
        border: 1px solid #78bd42;
        border-left: 6px solid #ff582e;
    }

    .e-toast-container .e-toast.e-toast-UM-error .e-toast-UM-error__content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .viewOnlyContainer {
        pointer-events: none;
        cursor: not-allowed;

        .e-input-group {
            background-color: #f4f4f4 !important;
            border-bottom-color: #949494 !important;
            cursor: not-allowed !important;
        }
    }

    #POAsContainer {
        .e-rowcell {
            text-transform: uppercase !important;
        }

        .e-headercell {
            height: 50px !important;
        }

        col {
            height: 50px !important;
        }
    }

    .hideButton {
        display: none !important;
    }

    .showButton {
        display: block !important;
    }

    #AddPOADialog {
        max-height: 377px;

        label {
            padding: 7px !important;
        }
    }

    .customSalesRepSearch {
        span {
            width: 77% !important;
        }
    }

    .mainRadioWrap {
        display: flex;
    }

    .OriginalPCPDetailsContainer {
        width: 100%;

        :first-child {
            margin-top: 0px !important;
        }

        .details {
            width: 100%;
            margin-top: 13px;

            :first-child {
                width: 25%;
            }

            :nth-child(2) {
                width: 6%;
            }
        }
    }

    .auditHistoryContainer {
        .tilesContainer {
            text-align: center;
            margin: 10px 0px 23px;

            .statButtonWrapper {
                display: inline-block;
                width: 17% !important;
                text-align: center;
                padding: 10px 20px 10px 20px;

                .button-card {
                    overflow: hidden;
                    background: #ffffff;
                    box-shadow: 0 2px 14px 0 rgb(0 0 0 / 10%);
                    border-radius: 6px;
                    border: none;
                    height: 100px;
                    padding-top: 0px;
                    padding-bottom: 0px;
                }

                .activeTiles {
                    border: 1px solid #00b3e3 !important
                }
            }
        }

        .e-dropdownbase {
            padding: 0px !important;
        }
    }
