@mixin animate($animation, $duration, $method, $times) {
    animation: $animation $duration $method $times;
}
@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

@mixin glow($i) {
    @include keyframes(glow#{$i}) {
        0% {
            opacity: 1;
        }
        #{$i * 10 }% {
            opacity: 1;
        }
        #{($i + 1) * 10}% {
            opacity: 0;
        }
        #{($i + 2) * 10}% {
            opacity: 1;
        }
    }
    @include animate(glow#{$i}, 3s, linear, 100);
}

path.st0 {
    @include glow(0);
}

path.st2 {
    @include glow(1);
}

path.st1 {
    @include glow(2);
}

path.st3 {
    @include glow(3);
}

path.st5 {
    @include glow(4);
}

path.st4 {
    @include glow(5);
}
