.screen {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinner {
    color: #0099a8;
}

.text {
    margin-top: 1rem;
    font-size: 1rem;
    color: #0b3a5d;
    font-weight: 500;
}
