.meeting-details-title {
    color: #fff;
    font-size: 25px;
    padding-bottom: 10px;
    font-weight: bolder;
}

.meeting-details-links-title {
    font-size: 16px;
}

.meeting-details {
    margin-top: -10px;
    padding-bottom: 5px;
}

.meeting-details-content {
    font-size: 18px;
}

.meeting-details-links-title {
    font-size: 22px;
    margin-top: 15px;
    font-weight: bolder;
}

.caret-info {
    float: right;
}

.caret-info:hover {
    cursor: pointer !important;
}

.virtual-visits-meeting-icon:hover {
    cursor: pointer !important;
}

.copy-member-link {
    background-color: rgb(0, 179, 227);
    width: 100%;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin: 10px auto 5px;
}

.phone-attendee,
.sms-attendee,
.email-attendee {
    margin-right: 10px;
}

.appointment-invitation-links div:hover span {
    cursor: pointer !important;
}

.member-invitation-links {
    min-width: max-content;
}
