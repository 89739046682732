.label {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 13px;
}

.sectionHeader {
    color: #03659a;
    font-size: 16px;
    margin: 10px;
    font-weight: 500;
    border-bottom: 1px solid #03659a5b;
}

.timeLineGroup {
    label {
        color: #424242;
    }
    input {
        height: 32px;
        width: 100%;
        padding-left: 0.5rem !important;
        background-color: #fafafa;
        border: 1px solid #9c9c9c;
    }
}

.inlineCheckbox {
    width: fit-content;
    margin-right: 2rem;
    margin-left: inherit;
}

.creationButton {
    width: 120px;
    margin-right: 1.5rem;
    font-weight: 500;
}

.editButton {
    width: 45%;
    margin: auto;
    font-weight: 500;
    color: #fff;
    &:hover {
        color: #000;
        border: 1px solid #000;
    }
}

.formButtonsGroup {
    display: flex;
    justify-content: end;
    gap: 1rem;
    width: 80%;
    margin-right: 1rem;

    .formButton {
        flex: 1;
        height: 35px;
        max-width: 180px;
    }
}

.historyLabelsGroup {
    margin-bottom: 1rem;
    .historyLabel {
        label span {
            color: #1675a9 !important;
        }
        &:first-of-type label span {
            color: #ce7a36 !important;
        }
    }
}
