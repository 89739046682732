.grid-alike-section {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    border: none;
}

.grid-alike-section:hover {
    border: 1px #8ed2e5 solid;
    margin: -1px;
}

.grid-header-right {
    float: right;
}
