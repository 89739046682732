.call-timer-container {
    left: 50px;
    position: relative;
    display: flex;
}

.call-timer-red {
    color: #e3165b;
    position: relative;
}

.call-timer-blue {
    color: #00b3e3;
    position: relative;
}

.call-timer-text {
    padding: 0 0 0 10px;
}
